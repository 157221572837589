import {defineMessages, FormattedMessage} from 'react-intl';
import {FREQUENCY_OPTIONS} from '../../../../../components/Organizations/constants';

const messages = defineMessages({
  noRestriction: {
    id: 'ClientConfigurationAnalyst.constants.noRestriction',
    defaultMessage: 'No Restriction'
  },
  restrictionOption: {
    id: 'ClientConfigurationAnalyst.constants.restrictionOption',
    defaultMessage: '{rating} or higher'
  },
  company: {
    id: 'ClientConfigurationAnalyst.constants.company',
    defaultMessage: 'Company'
  },
  phone: {
    id: 'ClientConfigurationAnalyst.constants.phone',
    defaultMessage: 'Phone'
  },
  mobile: {
    id: 'ClientConfigurationAnalyst.constants.mobile',
    defaultMessage: 'Mobile'
  },
  country: {
    id: 'ClientConfigurationAnalyst.constants.country',
    defaultMessage: 'Country'
  },
  month: {
    id: 'ClientConfigurationAnalyst.month',
    defaultMessage: 'month'
  },
  year: {
    id: 'ClientConfigurationAnalyst.year',
    defaultMessage: 'year'
  }
});

const RESTRICTIONS = {
  1: 'NO_RESTRICTION',
  2: 'TWO_OR_HIGHER',
  3: 'THREE_OR_HIGHER',
  4: 'FOUR_OR_HIGHER',
  5: 'FIVE_OR_HIGHER'
};

export const LINK_TYPES = {
  TELEPHONE_NUMBER : 'TELEPHONE_NUMBER',
  EMAIL_ADDRESS : 'EMAIL_ADDRESS',
  WEBSITE : 'WEBSITE',
  IOS_APP : 'IOS_APP',
  PLAY_STORE_APP: 'PLAY_STORE_APP'
};

export const CREATE_LINK_FORM_FIELDS = {
  linkName: 'linkName',
  linkType: 'linkType',
  linkContent: 'linkContent',
  appleAppURL: 'appleAppURL',
  appleAppId: 'appleAppId',
  playStoreAppURL: 'playStoreAppURL',
  playStoreAppId: 'playStoreAppId'
};

export const restrictionOptions = [
  {
    value: RESTRICTIONS['1'],
    label:  <FormattedMessage {...messages.noRestriction} />
  },
  ...[...Array(4).keys()].map(rating => {
    return {
      value: RESTRICTIONS[(rating + 2)],
      label: <FormattedMessage {...messages.restrictionOption} values={{rating: rating + 2}}/>
    };
  })
];

export const frequencyOptions = [
  {
    value: FREQUENCY_OPTIONS.MONTH,
    label:  <FormattedMessage {...messages.month} />
  },
  {
    value: FREQUENCY_OPTIONS.YEAR,
    label:  <FormattedMessage {...messages.year} />
  }
];

export const ORGANIZATION_CONFIGURATION_TYPES = {
  ACCESS_TO_ANALYST: 'access_to_analyst',
  CUSTOM_LOGO: 'custom_logo',
  CUSTOM_LINKS: 'custom_links',
  ONBOARDING: 'on_boarding',
  CUSTOM_LOGIN: 'custom_login',
  CUSTOM_CONTACTS: 'custom_contacts',
  OPTIONAL_FIELDS: 'optional_fields',
  CHECKIN_NOTIFICATIONS: 'checkin_notifications',
  CHECKIN_NOTIFICATION_ITEM: 'checkin_notification_item',
  MOBILE: 'mobile',
  CUSTOM_FROM_EMAIL: 'custom_from_email'
};

export const ONBOARDING_FIELDS = {
  COMPANY: {
    value: 'COMPANY',
    label: <FormattedMessage {...messages.company}/>
  },
  PHONE: {
    value: 'PHONE',
    label: <FormattedMessage {...messages.phone}/>
  },
  MOBILE: {
    value: 'MOBILE',
    label: <FormattedMessage {...messages.mobile}/>
  },
  COUNTRY: {
    value: 'COUNTRY',
    label: <FormattedMessage {...messages.country}/>
  }
};

export const DATA_RETENTION_FIELDS = {
  PERSON: 'PERSON',
  LOCATION_SHARE: 'LOCATION_SHARE'
};


export const ORGANIZATION_OPTIONAL_FIELDS = {
  LOCATION_CODE: "locationCodeEnabled",
  SUPERVISOR: "supervisorEnabled",
  RESPONSIBLE_SECURITY_OFFICER: "responsibleSecurityOfficerEnabled",
  REGION: "regionEnabled",
  BUSINESS_UNIT: "businessUnitEnabled"
};

export const PERSON_OPTIONAL_FIELDS = {
  LOCATION_CODE: "locationCode",
  SUPERVISOR: "supervisor",
  RESPONSIBLE_SECURITY_OFFICER: "responsibleSecurityOfficer",
  REGION: "region",
  BUSINESS_UNIT: "businessUnit"
};

export const MOBILE_CHECK_IN_CONFIG_FIELDS = {
  NAME: "name",
  CHECK_IN_TYPE: "checkinType",
  ORGANIZATIONS: "organizations",
  LANGUAGE: "language",
  RECIPIENTS: "recipients",
  CLIENT_IN_CONTACT_EMAIL: "clientInContactEmail"
};

export const CHECK_IN_TYPES = {
  CRISIS_SIGNAL: 'CRISIS_SIGNAL',
  SAFETY: 'SAFETY'
};

export const IN_CONTACT_DOMAIN = '@incontactemail.com';

export const CUSTOM_INTELLIGENCE_RESTRICTIONS = {
  KEYWORDS: 'keywords'
}
