import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {getOrgClientLogo} from '../../../redux/modules/session/actions';
import {ORGANIZATION_CONFIGURATION_TYPES} from '../OrganizationProfilePage/tabs/OrganizationClientConfiguration/ClientConfiguration.constants';

const OrganizationClientLogo = (props) => {

  const {setHasClientLogo} = props;
  const dispatch = useDispatch();

  const orgId = useSelector(state => state.session.currentPermissions.orgId);
  const clientLogoUrl = useSelector(state => state.session.orgClientLogoUrl);

  useEffect(() => {
    if(orgId) {
      dispatch(getOrgClientLogo(orgId, ORGANIZATION_CONFIGURATION_TYPES.CUSTOM_LOGO));
    }
  }, [orgId, dispatch]);

  useEffect(() => {
    setHasClientLogo(!!clientLogoUrl);
  },[clientLogoUrl, setHasClientLogo])

  return clientLogoUrl ? <img src={clientLogoUrl} alt="client top logo" className="client-logo-top-bar" /> : null;
}

OrganizationClientLogo.propTypes = {
  setHasClientLogo: PropTypes.func
};

export default OrganizationClientLogo;
