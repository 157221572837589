import {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Layout from 'antd/lib/layout';

import logo from '../../../assets/HORIZON_oneline.png';
import OrganizationClientLogo from '../../../containers/Organizations/OrganizationClientLogo/OrganizationClientLogo';

import './HeaderBar.css';

/**
 * Base app header bar.
 * Add additional contents as children
 */
export default function HeaderBar (props){

  const [hasClientLogo, setHasClientLogo] = useState(false);

  return (
    <Layout.Header className={classNames('HeaderBar', props.className)}>

      <div className="header-left">
        {props.children}
      </div>

      <div className="logo">
        <img src={logo} alt="Crisis24 Horizon Logo" className={classNames({'crisis-logo-top-bar': hasClientLogo})} />
        <OrganizationClientLogo setHasClientLogo={setHasClientLogo} />
      </div>

      <div className="header-right">
        {props.children}
      </div>

    </Layout.Header>
  );

}

HeaderBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
