import _ from 'lodash';
import {MODALITY_TYPES} from '../../../constants/preferences';
import {categoriesKeyMap, locationsKeyMap} from '../../../utils/preferences';

export const NOTIFICATION_TYPES = {
  ALERT_NOTIFICATION: 'ALERT_NOTIFICATION',
  LANGUAGE_WEB: 'LANGUAGE_WEB',
  LANGUAGE_MOBILE: 'LANGUAGE_MOBILE',
  NOTIFICATION_LANGUAGE: 'NOTIFICATION_LANGUAGE',
  DIB_NOTIFICATION: 'DIB_NOTIFICATION',
  ANALYSIS_NOTIFICATION: 'ANALYSIS_NOTIFICATION',
  RISK_RATINGS_CHANGE_NOTIFICATION: 'RISK_RATINGS_CHANGE_NOTIFICATION',
  SESSION_TIMEOUT: 'SESSION_TIMEOUT',
  TRIP_BOOKINGS: 'TRIP_BOOKING_NOTIFICATION',
  MULTIPLE_PEOPLE_ON_FLIGHT: 'MULTIPLE_PEOPLE_ON_FLIGHT_NOTIFICATION',
  NON_PREFERRED_AIRLINE: 'NON_PREFERRED_AIRLINE_TRIP_NOTIFICATION',
  INCIDENT_NOTIFICATION: 'INCIDENT_NOTIFICATION',
  IN_APP_MESSAGE_NOTIFICATION: 'IN_APP_MESSAGE_NOTIFICATION',
  EXPATRIATION_NOTIFICATION: 'EXPATRIATION_NOTIFICATION',
};

export function parseAlertNotificationPreference(preferences, type){
  const peopleAffected = _.get(preferences, ['peopleAffected']);
  const sitesAffected = _.get(preferences, ['sitesAffected']);
  const keyNames = {
    categories: categoriesKeyMap[type],
    locations: locationsKeyMap[type]
  };

  const modalities = [];
  if (preferences.emailEnabled) {
    modalities.push({type: MODALITY_TYPES.EMAIL});
  }
  if (preferences.smsEnabled) {
    modalities.push({type: MODALITY_TYPES.SMS});
  }

  const parsed = {
    name: preferences.name,
    type: type,
    config: {
      [keyNames.locations]: preferences[keyNames.locations]?.map(location => location.value),
      [keyNames.categories]: preferences[keyNames.categories]?.map(category => category.value),
      peopleAffected
    },
  };

  if(!_.isEmpty(sitesAffected)){
    // remove UI side property
    sitesAffected.sitesRadio = undefined;
    sitesAffected.attachReport = undefined;
    if(!_.isEmpty(sitesAffected.siteTypeIds)){
      sitesAffected.siteTypeIds = sitesAffected.siteTypeIds?.map?.(siteType => siteType.value)
      sitesAffected.siteIds = undefined
    }
    if(!_.isEmpty(sitesAffected.siteIds)){
      sitesAffected.siteIds = sitesAffected.siteIds?.map?.(site => site.value)
      sitesAffected.siteTypeIds = undefined
    }
    parsed.config.sitesAffected = sitesAffected;
  }


  if (modalities.length) {
    parsed.config.modalities = modalities;
  }

  if (preferences.severities?.length) {
    parsed.config.severities = preferences.severities.map(severity => severity.toUpperCase())
  }

  if (preferences.impactLevels) {
    parsed.config.impactLevels = preferences.impactLevels;
  }

  if (preferences.duration) {
    const [startAt, endAt] = preferences.duration;
    parsed.config.duration = {
      startAt: startAt.utc().startOf('day').format(),
      endAt: endAt.utc().endOf('day').format()
    };
  }

  return parsed;
}

// copied from src/redux/modules/preferences-dib/utils.js
// remove later
export function parseDibPreferenceObject(preferences) {
  const parsed = {
    name: preferences.name,
    type: NOTIFICATION_TYPES.DIB_NOTIFICATION,
    config: {
      locations: preferences.locations.map(location => location.value),
      categories: preferences.categories.map(category => category.value),
      severities: preferences.severities.map(severity => severity.toUpperCase()),
      timeOfDelivery: Number(preferences.timeOfDelivery.format('HH')),
      sortBy: preferences.sortBy
    }
  };

  return parsed;
}

export function parseSessionTimeoutPreferenceObject(preferences) {
  return {
    name: NOTIFICATION_TYPES.SESSION_TIMEOUT,
    type: NOTIFICATION_TYPES.SESSION_TIMEOUT,
    ...preferences
  }
}

export function parseLanguagePreferenceObject(preferredLocale) {
  return {
    name: 'Preferred Language',
    type: NOTIFICATION_TYPES.NOTIFICATION_LANGUAGE,
    config: {
      language: preferredLocale
    }
  };
}

export function parseAnalysisPreferenceObject(preferences) {
  return {
    name: preferences.name,
    type: NOTIFICATION_TYPES.ANALYSIS_NOTIFICATION,
    config: {
      locationIds: preferences.locations.map(location => location.value),
      categoryIds: preferences.categories.map(category => category.value),
      types: preferences.type.map(type => type.value),
      format: preferences.format
    }
  };
}


export function parseRatingsPreferenceObject(preferences) {
  return {
    name: preferences.name,
    type: NOTIFICATION_TYPES.RISK_RATINGS_CHANGE_NOTIFICATION,
    config: {
      locationIds: preferences.locations.map(location => location.value),
      categoryIds: preferences.categories.map(category => category.value),
      ratingsChangeGranularity: preferences.ratingsChangeGranularity
    }
  };
}

export function parseTripBookingPreferenceObject(preferences) {
  return {
    name: preferences.name,
    type: NOTIFICATION_TYPES.TRIP_BOOKINGS,
    config: {
      locationIds: preferences.locations?.map(location => location.value),
      personTypes: preferences.personTypes,
      minimumCountryRiskRating: preferences.minimumCountryRiskRating
    }
  };
}

export function parseMultiplePeopleOnFlightPreferenceObject(preferences) {
  return {
    name: preferences.name,
    type: NOTIFICATION_TYPES.MULTIPLE_PEOPLE_ON_FLIGHT,
    config: {
      personTypes: preferences.personTypes,
      totalPeople: preferences.totalPeople
    }
  };
}

// from src/redux/modules/preferences-nonPreferredAirline/utils.js
// remove later
export function parseNonPreferredAirlinePreferenceObject(preferences) {
  return {
    name: preferences.name,
    type: NOTIFICATION_TYPES.NON_PREFERRED_AIRLINE,
    config: {
      airlineIds: _.isEmpty(preferences.airlineIds) ? undefined : preferences.airlineIds,
      locationIds: _.isEmpty(preferences.locationIds) ? undefined : preferences.locationIds,
      airlinePreferenceRatings: _.isEmpty(preferences.airlinePreferenceRatings) ? undefined : preferences.airlinePreferenceRatings
    }
  }
}

export function parseInAppMessageNotificationPreferenceObject(preferences) {
  return {
    name: 'In-App Message Notification Preference',
    type: NOTIFICATION_TYPES.IN_APP_MESSAGE_NOTIFICATION,
    config: {
      inAppWebNotificationsEnabled: preferences.inAppWebNotificationsEnabled,
      emailNotificationsEnabled: preferences.emailNotificationsEnabled
    }
  };
}

export function parseExpatriationObject(preferences) {
  return {
    name: preferences.name,
    type: NOTIFICATION_TYPES.EXPATRIATION_NOTIFICATION,
    config: {
      personTypes: preferences.personTypes,
      locationIds: preferences.locationIds,
      expatriationEndingNotificationDays: preferences.expatriationEndingNotificationDays,
      countryRiskRatingThresholdRange: {
        from: preferences.countryRiskRatingThresholdRange[0],
        to: preferences.countryRiskRatingThresholdRange[1]
      }
    },
  };
}

export function parseNotificationPreferenceObject(preferences, type = NOTIFICATION_TYPES.ALERT_NOTIFICATION) {
  switch (type) {
    case NOTIFICATION_TYPES.ALERT_NOTIFICATION:
    case NOTIFICATION_TYPES.INCIDENT_NOTIFICATION: {
      return parseAlertNotificationPreference(preferences, type);
    }
    case NOTIFICATION_TYPES.NOTIFICATION_LANGUAGE: {
      return parseLanguagePreferenceObject(preferences.preferredLocale);
    }
    case NOTIFICATION_TYPES.ANALYSIS_NOTIFICATION: {
      return parseAnalysisPreferenceObject(preferences);
    }
    case NOTIFICATION_TYPES.RISK_RATINGS_CHANGE_NOTIFICATION: {
      return parseRatingsPreferenceObject(preferences);
    }
    case NOTIFICATION_TYPES.TRIP_BOOKINGS: {
      return parseTripBookingPreferenceObject(preferences);
    }
    case NOTIFICATION_TYPES.MULTIPLE_PEOPLE_ON_FLIGHT: {
      return parseMultiplePeopleOnFlightPreferenceObject(preferences);
    }
    case NOTIFICATION_TYPES.DIB_NOTIFICATION: {
      return parseDibPreferenceObject(preferences);
    }
    case NOTIFICATION_TYPES.SESSION_TIMEOUT: {
      return parseSessionTimeoutPreferenceObject(preferences);
    }
    case NOTIFICATION_TYPES.NON_PREFERRED_AIRLINE: {
      return parseNonPreferredAirlinePreferenceObject(preferences);
    }
    case NOTIFICATION_TYPES.IN_APP_MESSAGE_NOTIFICATION: {
      return parseInAppMessageNotificationPreferenceObject(preferences);
    }
    case NOTIFICATION_TYPES.EXPATRIATION_NOTIFICATION: {
      return parseExpatriationObject(preferences);
    }
    default:
      return preferences
  }

}


export function getApiOptions(getState) {
  const userId = _.get(getState(), ['preferences', 'userId']);
  const isSelf = _.get(getState(), ['preferences', 'isSelf']);
  return {userId, isSelf};
}
