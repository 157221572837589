import {useEffect, useMemo} from 'react'
import {useParams} from 'react-router-dom';

import {ROUTE_URLS} from '../../../constants/routes';
import {SELF_REGISTRATION_TYPES} from '../../../constants/self-registration';

import useSelfRegistrationPoliciesExists from '../../../hooks/services/self-registration/useSelfRegistrationPoliciesExist/useSelfRegistrationPoliciesExists';
import useFederatedSignIn from '../../../hooks/services/authentication/useFederatedSignIn';

const ProviderNameRedirectPage = () => {
  const {providerName} = useParams();
  const federatedSignIn = useFederatedSignIn();

  const providerNameFilter = useMemo(() => {
    return {
      providerName,
      type: SELF_REGISTRATION_TYPES.FSSO
    };
  }, [providerName]);

  const {results: providerNameResults} = useSelfRegistrationPoliciesExists(providerNameFilter);

  useEffect(() => {
    if(providerName && providerNameResults?.exists) {
      federatedSignIn(providerName);
    } else {    
      window.location.href = ROUTE_URLS.LOGIN;
    }
  }, [federatedSignIn, providerName, providerNameResults])


  return <></>
}

export default ProviderNameRedirectPage;