import _ from 'lodash';

export function isEmptyString(str){
  return _.isString(str) && _.isEmpty(str);
}
/**
 * Returns a copy of 'obj' with all entries containing empty string values removed
 * @param obj - any object
 * @return {object}
 */
export function removeEmptyStrings(obj) {
  return _.mapValues(obj, (value) => {
    if (isEmptyString(value)) {
      return undefined;
    }
    else {
      return value;
    }
  });
}

export function createFieldToWatchFunction(fieldPath) {
  return (prevValues, curValues) => {
    return _.get(prevValues, fieldPath) !== _.get(curValues, fieldPath);
  };
}
