import {defineMessages} from 'react-intl';
import {PRETRAVEL_TEMPLATE_DEFAULTS} from './CustomEmailTemplateDefaults/PreTravelNotificationDefaults';

export const SECTION_TYPES = {
  HEADER: 'HEADER',
  FOOTER: 'FOOTER',
  CUSTOM_MESSAGE: 'CUSTOM_MESSAGE'
};

// https://bitbucket.org/crisis24/wc4-api-docs/src/69989457bb1b753fe5e08c733f99bed0119e4ae1/src/docs/private/template-ms/template-ms.yaml#lines-668
export const EMAIL_TEMPLATE_TYPES = {
  ACCOUNT_ACTIVATION: 'ACCOUNT_ACTIVATION',
  PASSWORD_RESET: 'PASSWORD_RESET',
  ALERT_NOTIFICATION: 'ALERT_NOTIFICATION',
  INCIDENT_NOTIFICATION: 'INCIDENT_NOTIFICATION',
  ALERT_PREFERENCE_EXPIRY: 'ALERT_PREFERENCE_EXPIRY',
  DAILY_INTELLIGENCE_BRIEFING_DIB: 'DAILY_INTELLIGENCE_BRIEFING_DIB',
  DAILY_INTELLIGENCE_BRIEFING_NO_CONTENT: 'DAILY_INTELLIGENCE_BRIEFING_NO_CONTENT',
  SECURITY_OUTLOOK: 'SECURITY_OUTLOOK',
  HEALTH_OUTLOOK: 'HEALTH_OUTLOOK',
  LARGE_SCALE_EVENT: 'LARGE_SCALE_EVENT',
  RISK_RATINGS_CHANGES: 'RISK_RATINGS_CHANGES',
  CRISIS_SIGNAL_NOTIFICATIONS: 'CRISIS_SIGNAL_NOTIFICATION',
  SAFETY_CHECK_IN_NOTIFICATIONS: 'SAFETY_CHECK_IN_NOTIFICATION',
  TRIP_BOOKING_NOTIFICATION: 'TRIP_BOOKING_NOTIFICATION',
  MULTIPLE_PEOPLE_ON_FLIGHT_NOTIFICATION: 'MULTI_TRAVELER_FLIGHT_NOTIFICATION',
  TRAVELER_TRIP_EXPOSURE_NOTIFICATION: 'TRAVELER_TRIP_EXPOSURE_NOTIFICATION',
  NON_PREF_AIRLINE_TRIP_NOTIFICATION: 'NON_PREF_AIRLINE_TRIP_NOTIFICATION',
  PRE_TRAVEL_NOTIFICATION: 'PRE_TRAVEL_NOTIFICATION',
  PRE_EXPAT_NOTIFICATION: 'PRE_EXPAT_NOTIFICATION',
  EXPATRIATION: 'EXPAT_SCHEDULED_NOTIFICATION',
  EXPAT_ALERT_NOTIFICATION: 'EXPAT_ALERT_NOTIFICATION',
  ELEARN_COURSE_COMPLETION_NOTIFICATION: 'ELEARN_COURSE_COMPLETION_NOTIFICATION',
  MOBILE_IN_APP_MESSAGE_NOTIFICATION: 'MOBILE_IN_APP_MESSAGE_NOTIFICATION',
  MASS_NOTIFICATION: 'MASS_NOTIFICATION',
  // CUSTOM_FROM_EMAIL is not an email type should be it be here?
  CUSTOM_FROM_EMAIL: 'CUSTOM_FROM_EMAIL',
  SITE_POC_ALERT_NOTIFICATION: 'SITE_POC_ALERT_NOTIFICATION',
  SITE_POC_INCIDENT_NOTIFICATION: 'SITE_POC_INCIDENT_NOTIFICATION'
};

export const EMAIL_TYPE_MESSAGE = defineMessages({
  ACCOUNT_ACTIVATION: {
    id: 'ClientConfigEmailConstants.EmailType.ACCOUNT_ACTIVATION',
    defaultMessage: 'Account Activation'
  },
  PASSWORD_RESET: {
    id: 'ClientConfigEmailConstants.EmailType.PASSWORD_RESET',
    defaultMessage: 'Reset Password'
  },
  ALERT_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.ALERT_NOTIFICATION',
    defaultMessage: 'Alert Notification'
  },
  INCIDENT_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.INCIDENT_NOTIFICATION',
    defaultMessage: 'Incident Notification'
  },
  ALERT_PREFERENCE_EXPIRY: {
    id: 'ClientConfigEmailConstants.EmailType.ALERT_PREFERENCE_EXPIRY',
    defaultMessage: 'Alert Preference Expiry'
  },
  DAILY_INTELLIGENCE_BRIEFING_DIB: {
    id: 'ClientConfigEmailConstants.EmailType.DAILY_INTELLIGENCE_BRIEFING_DIB',
    defaultMessage: 'Daily Intelligence Briefing DIB'
  },
  DAILY_INTELLIGENCE_BRIEFING_NO_CONTENT: {
    id: 'ClientConfigEmailConstants.EmailType.DAILY_INTELLIGENCE_BRIEFING_NO_CONTENT',
    defaultMessage: 'Daily Intelligence Briefing No Content'
  },
  SECURITY_OUTLOOK: {
    id: 'ClientConfigEmailConstants.EmailType.SECURITY_OUTLOOK',
    defaultMessage: 'Security Outlook'
  },
  HEALTH_OUTLOOK: {
    id: 'ClientConfigEmailConstants.EmailType.HEALTH_OUTLOOK',
    defaultMessage: 'Health Outlook'
  },
  LARGE_SCALE_EVENT: {
    id: 'ClientConfigEmailConstants.EmailType.LARGE_SCALE_EVENT',
    defaultMessage: 'Large Scale Event'
  },
  RISK_RATINGS_CHANGES: {
    id: 'ClientConfigEmailConstants.EmailType.RISK_RATINGS_CHANGES',
    defaultMessage: 'Risk Ratings Changes '
  },
  CRISIS_SIGNAL_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.CRISIS_SIGNAL_NOTIFICATION',
    defaultMessage: 'Crisis Signal Notifications'
  },
  SAFETY_CHECK_IN_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.SAFETY_CHECK_IN_NOTIFICATION',
    defaultMessage: 'Safety Check-in Notifications'
  },
  TRIP_BOOKING_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.TRIP_BOOKING_NOTIFICATION',
    defaultMessage: 'Trip Bookings Notifications'
  },
  MULTI_TRAVELER_FLIGHT_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.MULTI_TRAVELER_FLIGHT_NOTIFICATION',
    defaultMessage: 'Multiple People on Flight Notifications'
  },
  NON_PREF_AIRLINE_TRIP_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.NON_PREF_AIRLINE_TRIP_NOTIFICATION',
    defaultMessage: 'Non-Preferred Airline Notifications'
  },
  PRE_TRAVEL_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.PRE_TRAVEL_NOTIFICATION',
    defaultMessage: 'Pre Travel Notifications'
  },
  TRAVELER_TRIP_EXPOSURE_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.TRAVELER_TRIP_EXPOSURE_NOTIFICATION',
    defaultMessage: 'Trips Affected By Alerts Notifications'
  },
  PRE_EXPAT_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.PRE_EXPAT_NOTIFICATION',
    defaultMessage: 'Pre Expatriation Notifications'
  },
  EXPAT_SCHEDULED_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.EXPAT_SCHEDULED_NOTIFICATION',
    defaultMessage: 'Expatriations'
  },
  EXPAT_ALERT_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.EXPAT_ALERT_NOTIFICATION',
    defaultMessage: 'Expatriations Affected by Alerts'
  },
  ELEARN_COURSE_COMPLETION_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.ELEARN_COURSE_COMPLETION_NOTIFICATION',
    defaultMessage: 'E-Learning Completion Notifications'
  },
  MOBILE_IN_APP_MESSAGE_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.MOBILE_IN_APP_MESSAGE_NOTIFICATION',
    defaultMessage: 'In-App Message Notifications'
  },
  MASS_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.MASS_NOTIFICATION',
    defaultMessage: 'Mass Notification'
  },
  CUSTOM_FROM_EMAIL: {
    id: 'ClientConfigEmailConstants.EmailType.CUSTOM_FROM_EMAIL',
    defaultMessage: 'Email Sent From'
  },
  SITE_POC_ALERT_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.SITE_POC_ALERT_NOTIFICATION',
    defaultMessage: 'Site POC Alert Notification'
  },
  SITE_POC_INCIDENT_NOTIFICATION: {
    id: 'ClientConfigEmailConstants.EmailType.SITE_POC_INCIDENT_NOTIFICATION',
    defaultMessage: 'Site POC Incident Notification'
  },
});

export const CUSTOM_EMAIL_DEFAULT_CONFIG = {
  [EMAIL_TEMPLATE_TYPES.PRE_TRAVEL_NOTIFICATION]: PRETRAVEL_TEMPLATE_DEFAULTS
};