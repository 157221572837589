import MapLayer from '../MapLayer';

export const PERSON_SOURCE_ID = 'person-source';
export const PERSON_LAYER_ID = 'person-layer';

export default class PersonLayer extends MapLayer {

  constructor() {
    super();

    this.data = false;
  }

  getLayerIds() {
    return [PERSON_LAYER_ID];
  }

  createSources() {
    if (this.map) {
      this.map.addSource(PERSON_SOURCE_ID, {
        type: 'geojson',
        data: this.data
      });
    }
  }

  createLayers() {
    if (this.map) {
      this.map.addLayer(
        {
          'id': PERSON_LAYER_ID,
          'source': PERSON_SOURCE_ID,
          'type': 'symbol',
          'layout': {
            'icon-image': [
              'case',
              ['==', ['get', 'redAcc'], true], `cluster_1 digit`,
              ['==', ['get', 'locType'], 1], `marker_person`,
              ['==', ['get', 'locType'], 2], `crisissignal_crisis`,
              ['==', ['get', 'locType'], 3], `marker_checkin`,
              ['==', ['get', 'locType'], 4], `icon_pin_flight_white`,
              ['==', ['get', 'locType'], 5], `icon_pin_hotel_white`,
              ['==', ['get', 'locType'], 6], `icon_pin_address_white`,
              ['==', ['get', 'locType'], 7], `icon_pin_rail`,
              ['==', ['get', 'locType'], 8], `icon_pin_car`,
              ['==', ['get', 'locType'], 9], `expatriation`,
              ['>=', ['length', ['get', 'idCountAbbrev']], 4], `cluster_4 plus digits`,
              ['==', ['length', ['get', 'idCountAbbrev']], 3], `cluster_3 digit`,
              ['==', ['length', ['get', 'idCountAbbrev']], 2], `cluster_2 digit`,
              'cluster_1 digit'
            ],
            'text-field': [
              'case',
              ['==', ['get', 'redAcc'], true], '1',
              ['get', 'idCountAbbrev']
            ],
            'text-size': 12,
            'icon-allow-overlap': true
          }
        }
      );
    }
  }

  setFilter(personFilter) {
    this.personFilter = personFilter;
  }

  /**
   * Set the source data for the csar country layer,
   * sets source to false if data is undefined.
   *
   * @param data
   */
  setData(data) {
    if (this.data !== data) {
      this.data = data || false;

      if (this.map) {
        this.map.getSource(PERSON_SOURCE_ID)?.setData?.(this.data);
      }
    }
  }
}
