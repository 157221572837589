import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
  MOBILE_LOCATION_SHARE: {
    id: 'constants.persons.MOBILE_LOCATION_SHARE',
    defaultMessage: 'Mobile Location Share'
  },
  SAFETY_CHECK_IN: {
    id: 'constants.persons.SAFETY_CHECK_IN',
    defaultMessage: 'Safety Check-in'
  },
  CRISIS_SIGNAL: {
    id: 'constants.persons.CRISIS_SIGNAL',
    defaultMessage: 'Crisis Signal'
  }
});

export const PERSON_TYPES = {
  EMPLOYEE_ID: 'EMPLOYEE_ID',
  STUDENT_ID: 'STUDENT_ID',
  FACULTY_ID: 'FACULTY_ID',
  CONTRACTOR_ID: 'CONTRACTOR_ID'
};

export const PERSON_USER_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
  ACTIVE_DISABLED: 'ACTIVE_DISABLED',
  INACTIVE_DISABLED: 'INACTIVE_DISABLED',
  NO_ACCOUNT: 'NO_ACCOUNT'
};

export const TRIP_APPROVAL_STATUS = {
  PENDING: 'PENDING',
  CREATED: 'CREATED',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED'
};

export const PERSON_EXPORT_DATA_OPTIONS = {
  PRIMARY: 'PRIMARY',
  ALL: 'ALL',
  EXCLUDE: 'EXCLUDE',
  LAST: 'LAST'
};

export const PERSON_EXPORT_DATA_FIELD_NAMES = {
  ACCOUNT_INFO_INCLUDED: 'accountInfoIncluded',
  EMAILS_INCLUDED: 'emailsIncluded',
  PHONES_INCLUDED: 'phonesIncluded',
  ADDRESSES_INCLUDED: 'addressesIncluded',
  NAMES_INCLUDED: 'namesIncluded',
  MOBILE_LOCATIONS_INCLUDED: 'mobileLocationsIncluded',
  TRIP_SEGMENTS_INCLUDED: 'tripSegmentsIncluded',
  EXPATRIATIONS_INCLUDED: 'includeExpatriations',
  SITE_DETAILS_INCLUDED: 'includeSites',
  EMERGENCY_CONTACTS_INCLUDED: 'emergencyContactsIncluded',
  IDENTIFIERS_INCLUDED: 'identifiersIncluded'
};

export const CHECK_IN_REASONS = {
  MOBILE_LOCATION_SHARE: 'MOBILE_LOCATION_SHARE',
  CRISIS_SIGNAL: 'CRISIS_SIGNAL',
  SAFETY_CHECK_IN: 'SAFETY_CHECK_IN',
  MOBILE_BACKGROUND_SHARE: 'MOBILE_BACKGROUND_SHARE',
};

export const MOBILE_LOCATIONS = {
  CHECK_IN_REASON: 'checkInReason',
  LOCATION: 'location',
  OPTIONAL_TEXT: 'optionalText',
  MEASURED_DATE: 'measuredDate',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  SOURCE: 'source',
  RETRIES: 'resendAttemptsCount',
};

export const PERSON_LOCATION_TYPES = {
  MOBILE_BACKGROUND_SHARE: 'MOBILE_BACKGROUND_SHARE',
  ADDRESSES: 'ADDRESSES',
  TRIP_SEGMENTS: 'TRIP_SEGMENTS'
};

export const LOCATION_TYPES_SEARCH_RANGE = {
  LAST_ONLY: 'lastOnly',
  ALL: 'ALL',
};

export const BULK_UPLOAD_COLUMN_KEYS = {
  IMPORT_STATUS: 'status',
  UPLOADED_BY: 'uploadedBy',
  UPLOADED_DATE: 'createdAt',
  ACTIONS: 'fileId',
  ORGANIZATION: 'defaultOrganizationName'
};

export const BULK_UPLOAD_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  PROCESSING: 'PROCESSING'
};

export const PERSON_ADDRESS = {
  LINE1: 'line1',
  LINE2: 'line2',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',
  COUNTRY: 'country',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  ACCURACY: 'accuracy'
};

const MAIN_LOCATION_ACCURACY = {
  STREET_ADDRESS: 'STREET_ADDRESS',
  POSTAL_CODE: 'POSTAL_CODE',
  CITY: 'CITY',
  STATE_PROVINCE_TERRITORIES: 'STATE_PROVINCE_TERRITORIES',
  COUNTRY: 'COUNTRY',
  USER_PROVIDED: 'USER_PROVIDED',
}

export const LOCATION_ACCURACY_GEO = {
  ...MAIN_LOCATION_ACCURACY,
  NOT_GEOCODED: 'NOT_GEOCODED'
}

export const  LOCATION_ACCURACY = {
  ...MAIN_LOCATION_ACCURACY,
  UNKNOWN: 'UNKNOWN'
};


export const CHECK_IN_MESSAGE_ID_MAP = {
  [CHECK_IN_REASONS.MOBILE_LOCATION_SHARE]: <FormattedMessage {...messages.MOBILE_LOCATION_SHARE}/>,
  [CHECK_IN_REASONS.CRISIS_SIGNAL]: <FormattedMessage {...messages.CRISIS_SIGNAL}/>,
  [CHECK_IN_REASONS.SAFETY_CHECK_IN]: <FormattedMessage {...messages.SAFETY_CHECK_IN}/>,
  // safety check in type from checkin-notifications-ms (SAFETY) is different from checkin-ms (SAFETY_CHECK_IN)
  SAFETY: <FormattedMessage {...messages.SAFETY_CHECK_IN}/>,
};


export const OCCUPANT_LOCATION_TYPE = 'OCCUPANT';
export const POC_LOCATION_TYPE = 'POC';

export const MERGE_PROFILE_KEYS = {
  FIRST_NAME: 'firstName',
  EMAIL: 'email',
  ORGANIZATION: 'organization',
  MODIFIED_DATE: 'modifiedDate',
  SOURCE: 'source',
  PRIMARY_IDENTIFIER: 'primaryIdentifier'
};

export const SAVED_SEARCH_FIELD = {
  ID: 'id',
  NAME: 'name',
  DESCRIPTION: 'description',
  FILTER: 'filter',
  DATE_RANGE_TYPE: 'dateRangeType'
}

export const PERSON_EMERGENCY_CONTACTS_FIELD = {
  FIRST: 'first',
  LAST: 'last',
  RELATIONSHIP: 'relationship',
  PHONE: 'phone',
  EMAIL: 'email'
};

export const PERSON_MATCHING_OPTIONS = {
  DISPLAY_NAME: 'displayName',
  ENUM: 'enum'
}