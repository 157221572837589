import * as types from './types';
import {parseOrganizationHierarchyResponse, parseUserResults} from './utils';
import _ from 'lodash';
import {ZendeskAPI} from 'react-zendesk';
import queryString from 'query-string';

import analyticsService from '../../../services/analytics';

import {ORGANIZATION_CONFIGURATION_TYPES} from '../../../containers/Organizations/OrganizationProfilePage/tabs/OrganizationClientConfiguration/ClientConfiguration.constants';
import {replace} from 'connected-react-router';
import {preferencesActions} from '../preferences';
import {NOTIFICATION_TYPES} from '../preferences/utils';
import {setLanguage} from '../localization/actions';
import {clearAlertDetailsCustomTimeRangeLocalStorage} from '../alertDetails/utils';
import {CSRF_STORAGE_KEY, default as authStorageApi} from '../../../services/api/auth-storage';

export const IDLE_TIMEOUT_MS_DEFAULT = 1000 * 60 * 60 * 2; // 7,200,000 2 hours
export const HOURS_TO_MILLISECONDS_MULTIPLIER = 1000 * 60 * 60; // 3600000 ms in an hour

/**
 * Checks custom auth, only if custom auth flow throws err then it will check if user is already
 * authenticated dispatching requestAuthentication if not and initializeUser if authentication exists
 *
 * @returns {function(*, *, {auth: *}): *}
 */
export const initialize = () => {
  return (dispatch, getState, {auth}) => {
    return checkCustomAuth(dispatch, getState, auth)
      .catch(() => {
        return auth.hasCurrentSession()
          .then(isAuthenticated => {
            if (!isAuthenticated) {
              // Bail out if initializing without authentication
              return dispatch(requestAuthentication());
            }
            return dispatch(initializeUser());
          });
      });
  };
};

/**
 * Checks state router for query params, if has username and jwt attempt to signin using the provided info
 * (this will override current user if exists). Upon success will check and override locale language setting
 * based on the query param and initializeUser passing override persona from the query params.
 *
 * NOTE* not expected to be called except during the session initializing process
 *
 * @returns {(function(*, *, {auth: *}): (*))|*}
 */
export function checkCustomAuth(dispatch, getState, auth) {
  const queryParams = queryString.parse(_.get(getState(), ['router', 'location', 'search'], {}));
  const {jwt, username, 'encoded-username': encodedUsername, locale, persona} = queryParams;
  const {hideHeader} = queryParams;

  if (!_.isNil(hideHeader)) {
    dispatch({type: types.MOBILE_HIDE_MAIN_HEADER});
  }

  if ((encodedUsername || username) && jwt) {
    // clear query params
    dispatch(replace({search: ''}));

    const _username = username || decodeURIComponent(atob(encodedUsername));

    return auth.signIn(_username)
      .then(user => user.challengeName === 'CUSTOM_CHALLENGE' ?
        auth.sendCustomChallengeAnswer(user, jwt) :
        Promise.reject('failed custom auth')
      )
      // override/set localstorage if local was passed
      .then(() => locale && dispatch(setLanguage(locale)))
      .then(() => dispatch(initializeUser(persona)));
  }
  else {
    return Promise.reject('failed custom auth');
  }
}

/**
 * Sets up user...
 * dispatches setSessionIdleTimeout
 * dispatches getLoggedInUserLanguagePreference
 * dispatches requestAllReports
 *
 * dispatches getCurrentUser... then checkOnboardingRequirement and initializeAnalytics
 *
 * @returns {function(*, *): *}
 */
export function initializeUser(personaOverride) {
  return (dispatch, getState) => {
    // Set Idle timeout based on session
    dispatch(setSessionIdleTimeout());

    if (process.env.REACT_APP_XSRF_API_PATHS) {
      return dispatch(getCsrfToken())
        .finally(() => {
          dispatch(preferencesActions.getLoggedInUserLanguagePreference());

          return dispatch(getCurrentUser(personaOverride))
            .then(() => {
              dispatch(checkOnboardingRequirement());
              analyticsService.initializeAnalytics(getState());
            })
            .catch(error => {
              console.error(error);
            });
        });
    }
    else {
      dispatch(preferencesActions.getLoggedInUserLanguagePreference());

      return dispatch(getCurrentUser(personaOverride))
        .then(() => {
          dispatch(checkOnboardingRequirement());
          analyticsService.initializeAnalytics(getState());
        })
        .catch(error => {
          console.error(error);
        });
    }
  };
}

function getCsrfToken() {
  return (dispatch, getState, {xsrf}) => {
    return xsrf.requestCsrfToken()
      .then(response => {
        authStorageApi.setCsrfToken(response.data?.[CSRF_STORAGE_KEY]);
      });
  };
}

/**
 * Checks for current users onboarding requirements based on person data and thier
 * organization config.
 *
 *
 * @returns {(function(*, *, {persons: *, organization: *}): void)|*}
 */
export function checkOnboardingRequirement() {
  return (dispatch, getState, {persons, organization}) => {
    const personId = _.get(getState(), ['session', 'personId']);

    persons.getPersonConfigPattern({pathParams: {personId}})
      .then(personResult => {
        // person must have organization and it can only be part of one hierarchy
        const organizationId = personResult.data.organizations[0].organizationId;
        // start calling person onboarding to save loading time
        const personOnboardingPromise = persons.getOnboardingInfo(personId);

        return organization.getConfiguration(organizationId, ORGANIZATION_CONFIGURATION_TYPES.ONBOARDING)
          .then(onboardingFieldsResults => {
            // if onboarding fields exists and is not empty request persons onboarding info
            if (!_.isEmpty(onboardingFieldsResults?.data?.additionalFields)) {
              return personOnboardingPromise
                .then(() => {
                  // did not return an error user already has onboarding data submitted
                  return dispatch({
                    type: types.SET_ONBOARDING_FIELDS,
                    payload: false
                  });
                })
                .catch((error) => {
                  // onboarding is required if no data exists (404 error)
                  if (error.response.status === 404) {
                    return dispatch({
                      type: types.SET_ONBOARDING_FIELDS,
                      payload: onboardingFieldsResults?.data?.additionalFields
                    });
                  }
                });
            }
            else {
              return dispatch({
                type: types.SET_ONBOARDING_FIELDS,
                payload: false
              });
            }
          })
          .catch(error => {
            if (error.response.status === 404) {
              // do nothing no config exists for org
            }
            else {
              console.error(error.response.message);
            }

            return dispatch({
              type: types.SET_ONBOARDING_FIELDS,
              payload: false
            });
          });
      })
      .catch(() => {
        return dispatch({
          type: types.SET_ONBOARDING_FIELDS,
          payload: false
        });
      });
  };
}

/**
 * Dispatch USER_REQUEST, request users.getSelf, then dispatch
 * USER_SUCCESS with the results or USER_ERROR if errored.
 *
 * @returns {function(*, *, {users: *}): Promise<T | never>}
 */
export const getCurrentUser = (personaOverride) => {
  return (dispatch, getState, {users}) => {

    dispatch({type: types.USER_REQUEST});

    const locale = getState()?.localization?.locale;
    const personaId = personaOverride || getState()?.session?.currentPermissions?.orgId;

    return users.getSelf(locale)
      .then(results => dispatch({
        type: types.USER_SUCCESS,
        payload: parseUserResults(results, personaId)
      }))
      .then(() => {
        return users.getSelfOrganizations()
          .then(userOrganizationResponse => {
            const {fullPermissions} = getState().session;
            dispatch({
              type: types.USER_ORG_TREE_SUCCESS,
              payload: parseOrganizationHierarchyResponse(userOrganizationResponse, fullPermissions)
            });
          });
      })
      .catch(err => {
        dispatch({
          type: types.USER_ERROR,
          payload: err
        });
        dispatch(logout());
      });
  };
};

/**
 * Dispatch SET_IDLE_TIMEOUT with value from auth.getIdleTimeout
 *
 * @returns {function(*, *, {auth: *}): *}
 */
export const setSessionIdleTimeout = () => {
  return (dispatch, getState, {preferences}) => {
    dispatch({type: types.IDLE_TIMEOUT_REQUEST});

    // pull session timeout from preference or default
    return preferences.getUserPreferences(NOTIFICATION_TYPES.SESSION_TIMEOUT, {isSelf: true})
      .then((result) => {
        const sessionTimeoutPreference = result?.data?.[0]?.preference.config.durationHours;
        const sessionTimeout = sessionTimeoutPreference && (sessionTimeoutPreference * HOURS_TO_MILLISECONDS_MULTIPLIER);
        return dispatch({
          type: types.IDLE_TIMEOUT_SUCCESS,
          payload: {idleTimeout: sessionTimeout || IDLE_TIMEOUT_MS_DEFAULT}
        });
      })
      .catch(() => {
        dispatch({
          type: types.IDLE_TIMEOUT_FAILURE,
          payload: {idleTimeout: IDLE_TIMEOUT_MS_DEFAULT}
        });
      });
  };
};

/**
 * Dispatch LOGOUT_REQUEST and call auth.logout.
 *
 * @returns {function(*, *, {auth: *}): (*|void)}
 */
export const logout = () => {
  return (dispatch, _getState, {auth}) => {
    clearAlertDetailsCustomTimeRangeLocalStorage();
    ZendeskAPI('webWidget', 'logout');
    dispatch({type: types.LOGOUT_REQUEST});
    return auth.logout();
  };
};

/**
 * Dispatch AUTHENTICATION_REQUEST and call auth.redirectToLogin
 *
 * @returns {function(*, *, {auth: *}): (*|void)}
 */
export const requestAuthentication = () => {
  return (dispatch, _getState, {auth}) => {
    dispatch({type: types.AUTHENTICATION_REQUEST});
    return auth.redirectToLogin();
  };
};

export const setCurrentPersona = (orgId) => {
  return (dispatch, getState) => {
    const currentOrgId = _.get(getState(), 'session.currentPermissions.orgId');
    const currentPostLoginEnabledState = _.get(getState(), 'session.postLoginEnabled');
    const postLoginEnabled = currentOrgId === orgId ? currentPostLoginEnabledState : undefined;
    dispatch({type: types.SET_CURRENT_PERSONA, payload: {id: orgId, postLoginEnabled}});
    return analyticsService.updateAnalytics(getState());
  };
};

/**
 *
 * @returns {Function}
 */
export const getOrgClientLogo = (orgId, orgConfigType) => {
  return (dispatch, _getState, {organization}) => {
    dispatch({type: types.GET_ORG_CLIENT_LOGO_REQUEST});

    return organization.getConfiguration(orgId, orgConfigType)
      .then((result) => {
        return dispatch({
          type: types.GET_ORG_CLIENT_LOGO_SUCCESS,
          payload: {
            logoUrl: result.data.logoUrl
          }
        });
      })
      .catch(() => {
        dispatch({type: types.GET_ORG_CLIENT_LOGO_FAILURE});
      });
  };
};

/**
 *
 * @returns {Function}
 */
export const getOrgClientPostLoginConfig = (orgId) => {
  return (dispatch, _getState, {organization}) => {
    dispatch({type: types.GET_ORG_CLIENT_POST_LOGIN_CONFIG_REQUEST});

    return organization.getConfiguration(orgId, ORGANIZATION_CONFIGURATION_TYPES.CUSTOM_LOGIN)
      .then((result) => {
        return dispatch({
          type: types.GET_ORG_CLIENT_POST_LOGIN_CONFIG_SUCCESS,
          payload: {
            postLoginEnabled: result.data?.postLoginEnabled
          }
        });
      })
      .catch(() => {
        dispatch({type: types.GET_ORG_CLIENT_POST_LOGIN_CONFIG_FAILURE});
      });
  };
};

/**
 *
 * @returns {Function}
 */
export const saveOnboardingData = (personId, onboardingData) => {
  return (dispatch, _getState, {persons}) => {
    dispatch({type: types.SAVE_ONBOARDING_FIELDS_REQUEST});

    return persons.createOnboardingInfo(personId, onboardingData)
      .then(() => {
        return dispatch({
          type: types.SAVE_ONBOARDING_FIELDS_SUCCESS
        });
      })
      .catch(() => {
        dispatch({type: types.SAVE_ONBOARDING_FIELDS_FAILURE});
      });
  };
};
