import _ from 'lodash';
import moment from 'moment';

import {defineMessages} from 'react-intl';

import parsePagedResults from '../../utils/parsePagedResults';
import {PERSON_EXPORT_DATA_FIELD_NAMES, PERSON_EXPORT_DATA_OPTIONS} from '../../../constants/persons';
import {PERSON_MOBILE_LOCATIONS_VALUES, PERSON_SITE_VALUES, PERSON_TRIPS_VALUES} from '../worldMap/person/utils';
import {
  ADDRESS,
  AIRLINE_ID,
  AIRPORT_ID,
  ALL,
  EXPATRIATION,
  FLIGHT_NUMBER_LIKE,
  TRAIN_NUMBER,
  RAILWAY,
  ML_ALL,
  ML_LAST_ONLY,
  PERSON_GROUP,
  PERSON_MOBILE_LOCATIONS_RADIO
} from '../../../components/Person/PersonOptionsSelection/form-constants';
import {
  VIEW_CRISIS_SIGNAL_LOCATIONS,
  VIEW_DELETED_PERSONS,
  VIEW_EXPATRIATIONS,
  VIEW_PERSON_TYPES,
  VIEW_PERSONS,
  VIEW_PERSONS_GEOCODED_ADDRESS,
  VIEW_PERSONS_MOBILE_LOCATION,
  VIEW_SAFETY_CHECKIN_SIGNAL_LOCATIONS,
  VIEW_SITES,
  VIEW_TRIPS
} from '../../../constants/permissions';
import localization from '../../../services/localization';

// TODO try to remove this to follow normal patterns
// DO NOT FOLLOW THESE PATTERNS of having api calls in utils this is a stop gap solution
import sitesApi from '../../../services/api/sites';
import airlineApi from '../../../services/api/airlines';
import geoLocationsApi from '../../../services/api/geo-locations';
import {horizonLocalStorage} from '../../../utils/local-storage';

const addressAccuracyMessages = defineMessages({
  unknown: {
    id: 'redux.person.utils.addressAccuracy.unknown',
    defaultMessage: 'Unknown'
  },
  notGeocoded: {
    id: 'redux.person.utils.addressAccuracy.notGeocoded',
    defaultMessage: 'Not Geocoded'
  },
  userProvided: {
    id: 'redux.person.utils.addressAccuracy.userProvided',
    defaultMessage: 'User Provided'
  },
  street: {
    id: 'redux.person.utils.addressAccuracy.street',
    defaultMessage: 'Street'
  },
  provinceState: {
    id: 'redux.person.utils.addressAccuracy.provinceState',
    defaultMessage: 'Province/State'
  },
  zipPostalCode: {
    id: 'redux.person.utils.addressAccuracy.zipPostalCode',
    defaultMessage: 'Zip/Postal Code'
  },
  country: {
    id: 'redux.person.utils.addressAccuracy.country',
    defaultMessage: 'Country'
  },
  city: {
    id: 'redux.person.utils.addressAccuracy.city',
    defaultMessage: 'City'
  }
});

const addressLabelMessages = defineMessages({
  none: {
    id: 'redux.person.utils.addressLabel.none',
    defaultMessage: 'None'
  }
});

//The User Statuses used only in Person Search.
export const PERSON_STATUSES = {
  NO_ACCOUNT: 'noAccount',
  INACTIVE: 'inactive',
  ACTIVE: 'active',
  DISABLED: 'disabled'
};

export const DISABLED_PERSON_STATUSES = [
  'INACTIVE_DISABLED',
  'ACTIVE_DISABLED',
  'DISABLED_DELETED'
];

export const constants = {
  PERSON: 'person',
  PERSON_FIRSTNAME: 'firstName',
  PERSON_MIDDLENAME: 'middleName',
  PERSON_LASTNAME: 'lastName',
  PERSON_IDENTIFIER: 'identifier',
  PERSON_EMAIL: 'email',
  PERSON_CITY: 'city',
  PERSON_STATE: 'state',
  PERSON_ZIP: 'zip',
  PERSON_COUNTRY: 'country',
  PERSON_PHONE_NUMBER: 'phone',
  PERSON_ORGANIZATIONS: 'organizationIds',
  PERSON_ORGANIZATIONS_KEY: 'organizationsKey',
  PERSON_LOCATION: 'locationId',
  PERSON_LOCATIONS: 'locationIds',
  PERSON_OR_LOCATIONS_IDS: 'orLocationIds',
  PERSON_LOCATIONS_KEY: 'locationsKey',
  PERSON_STATUS: 'userStatuses',
  PERSON_DATE_RANGE: 'personDateRange',
  PERSON_DATE_START: 'dateStart',
  PERSON_DATE_END: 'dateEnd',
  PERSON_MAP_CLUSTER: 'cluster',
  PERSON_MAP_POLYGONS: 'polygons',
  PERSON_TYPES: 'personTypes',
  PERSON_EXCLUDES_TYPES: 'excludePersonTypes',
  SHOW_DELETED_PEOPLE_ONLY: 'showDeletedPeopleOnly',
  ASCEND: 'ascend',
  ASC: 'asc',
  DESCEND: 'descend',
  DESC: 'desc',
  FILTER_STRING: 'searchText',
  FILTER_SEARCH_MINIMUM_LENGTH: 3,
  INCLUDE: 'include',
  TYPES: 'types',
  PERSON_LOCATION_TYPES: 'personLocationTypes',
  MOBILE_LOCATION_SCOPE: 'mobileLocationScope',
  ADDRESS_LABELS: 'addressLabels',
  ADDRESS_ACCURACY: 'addressAccuracies',
  ITINERARY_NAME: 'itineraryNameLike',
  HOTEL_NAME: 'hotelNameLike',
  RECORD_LOCATOR: 'itineraryRecordLocatorLike',
  TRIP_NOTES: 'itineraryNotesLike',
  TRIP_STATUS: 'itineraryStatuses',
  SHOW_DELETED_TRIPS: 'showDeletedTrips',
  EXPATRIATION_NAME: 'expatriationName',
  SHOW_DELETED_EXPATRIATIONS: 'showDeletedExpatriations',
  PERSON_PROFILE_STATUS: 'profileStatuses',
  HORIZON_FILTER_KEY: 'horizonFilterKey',
  SITE_IDS: 'siteIds',
  SITE_TYPE_IDS: 'siteTypeIds',
  SITE_ADDRESS: 'siteAddress',
  CUSTOM_FIELDS: 'customFields',
  LOCATION_RATINGS: 'locationRatings',
  LOCATION_RATINGS_ENABLED_FIELD: 'locationRatingsEnabledField',
  PRIMARY_ORG_ID: 'primaryOrgId'
};

/**
 *
 * @param sortField {string} One of constants.PERSON_LASTNAME or constants.PERSON_EMAIL - Sort by name or email
 * @param tableSortDirection {string} One of constants.ASCEND or constants.DESCEND - increasing or decreasing sort order
 * @returns {Array<string>}
 */
export function createSortParamsForPersonsList(sortField, tableSortDirection) {
  const sortDir = tableSortDirection === constants.DESCEND ? constants.DESC : constants.ASC;
  let sortParams = [`${sortField},${sortDir}`];

  if (sortField === constants.PERSON_LASTNAME) {
    sortParams.push(`${constants.PERSON_FIRSTNAME},${sortDir}`); // adds firstName to sort to allow full name sorting
    sortParams.push(`${constants.PERSON_MIDDLENAME},${sortDir}`); // adds middleName to sort to allow full name sorting
  }

  return sortField && tableSortDirection ? sortParams : undefined;
}

/**
 *
 * @param personsListResults
 * @returns {{personsList: *, pageable: {pageNumber: *, pageSize: *, sort: null, totalElements: *, direction: string}}}
 */
export function parsePersonsListResults(personsListResults) {
  const pageable = parsePagedResults(personsListResults);
  const personsList = _.get(personsListResults, ['data', 'content'], []);

  return {
    pageable,
    personsList
  };
}

const getFilterParameter = (params, key, valueFunction) => params[key] && params[key].length > 0 ?
  {[key]: params[key].map(valueFunction)} :
  undefined;

const getLocationValue = (location) => parseInt(location, 10);
const getSelectedValue = (item) => item?.value || item;

const getUserStatusValue = (selectedStatus) => {
  return (selectedStatus === PERSON_STATUSES.DISABLED) ?
    DISABLED_PERSON_STATUSES :
    _.findKey(PERSON_STATUSES, (status) => status === selectedStatus);
};

const getDateRange = (params, key) => {
  const dateRange = params[key];

  if (_.isNil(dateRange)) {
    return undefined;
  }

  const [start, end] = dateRange;

  return {dateEnd: end.toISOString(), dateStart: start.toISOString()};
};

const getLikeName = (params, key) => (params[key] ? {[`${key}Like`]: params[key]} : {});

export function parsePersonLocationTypes(object) {
  if (_.isNil(object)) {
    return null;
  }

  const personLocationTypesValues = Object.keys(PERSON_MOBILE_LOCATIONS_VALUES);
  const personTripsValues = Object.keys(PERSON_TRIPS_VALUES);
  const personSiteValues = Object.values(PERSON_SITE_VALUES);
  const personLocationTypes = _.isArray(object) ?
    object :
    object?.[PERSON_GROUP]?.filter(item => {
      return personLocationTypesValues.includes(item) || personTripsValues.includes(item) || item === ADDRESS || item === EXPATRIATION ||
        personSiteValues.includes(item);
    });
  const mobileLocationScope = object?.[PERSON_MOBILE_LOCATIONS_RADIO] === ML_ALL ? 'ALL' : 'LAST';
  const hasMobileLocations = object?.[PERSON_GROUP]?.filter(item => {return personLocationTypesValues.includes(item)})?.length > 0;

  return {
    [constants.PERSON_LOCATION_TYPES]: personLocationTypes?.length > 0 ? personLocationTypes : undefined,
    [constants.MOBILE_LOCATION_SCOPE]: hasMobileLocations ? mobileLocationScope : undefined
  };
}

/**
 * transform personTypes for the API
 * since the API only accept personTypes or excludePersonTypes
 *
 * @returns {*}
 */
export function parsePersonTypes(object) {
  if (_.isNil(object) || _.isNil(object.include)) {
    return null;
  }

  return {[object.include]: object.types};
}

export function parseFilterParams(params = {}) {
  if (_.isNil(params)) {
    return {};
  }
  // Produce correct filter parameters for body of API request
  return Object.keys(params).reduce((previousValue, key) => {
    switch (key) {
      case constants.PERSON_LOCATIONS:
        return {...previousValue, ...(getFilterParameter(params, key, getLocationValue))};
      case constants.PERSON_ORGANIZATIONS:
      case constants.ADDRESS_LABELS:
      case constants.ADDRESS_ACCURACY:
        return {...previousValue, ...(getFilterParameter(params, key, getSelectedValue))};
      case constants.PERSON_STATUS: {
        const statusValues = getFilterParameter(params, key, getUserStatusValue);
        if (statusValues) {
          const flattened = {[key]: statusValues[key].flat()};
          return {...previousValue, ...flattened};
        }
        else {
          return previousValue;
        }
      }
      case constants.PERSON_DATE_RANGE:
        return {...previousValue, ...(getDateRange(params, key))};
      case constants.PERSON_FIRSTNAME:
      case constants.PERSON_LASTNAME:
      case constants.PERSON_MIDDLENAME:
      case constants.EXPATRIATION_NAME:
        return {...previousValue, ...(getLikeName(params, key))};
      case constants.SHOW_DELETED_PEOPLE_ONLY:
        return params[constants.SHOW_DELETED_PEOPLE_ONLY] ? {...previousValue, 'status': 'DELETED'} : {...previousValue};
      case constants.SHOW_DELETED_TRIPS:
        return params[constants.SHOW_DELETED_TRIPS] ? {...previousValue, 'itineraryDeletedStatuses': ['ACTIVE', 'DELETED']} : {...previousValue};
      case constants.SHOW_DELETED_EXPATRIATIONS:
        return params[constants.SHOW_DELETED_EXPATRIATIONS]
          ? {...previousValue, 'expatriationDeletedStatuses': ['ACTIVE', 'DELETED']}
          : {...previousValue};
      case constants.PERSON_LOCATION_TYPES:
        return {
          ...previousValue,
          ...parsePersonLocationTypes(params[key])
        };
      case constants.PERSON_TYPES:
        return {
          ...previousValue,
          ...parsePersonTypes(params[key])
        };
      case constants.PERSON_IDENTIFIER:
      case TRAIN_NUMBER:
      case RAILWAY:
        return {...previousValue, [key]: params[key] ? params[key] : undefined};
      case constants.ITINERARY_NAME:
      case constants.RECORD_LOCATOR:
      case FLIGHT_NUMBER_LIKE:
      case constants.HOTEL_NAME:
      case constants.TRIP_NOTES:
        return {...previousValue, ...params[key] ? {[key]: params[key]} : {}};
      case AIRLINE_ID:
      case AIRPORT_ID:
        return {...previousValue, [key]: params[key]?.id};
      case constants.SITE_ADDRESS:
        return {...previousValue, siteAddressLike: params[key] ? params[key] : undefined};
      case constants.SITE_IDS:
        return {...previousValue, siteIds: params[key]?.map(site => site.value)};
      case constants.CUSTOM_FIELDS:
        return {...previousValue, customFields: params[key]
          ? Object.keys(params[key])
            .map(id => {
              const value = params[key][id];
              return {id: id, valueLike: value};
            })
            .filter(item => item.valueLike)
          : undefined};
      case constants.LOCATION_RATINGS:
        return params[key] ?
          {
            ...previousValue,
            locationRatingRange: {
              start: params[key][0],
              end: params[key][1],
            },
            // this field is just used to control enabling/disabling/clearing the field range selector when toggled
            // likely ignored by the service but omit anyway
            [constants.LOCATION_RATINGS_ENABLED_FIELD]: undefined
          } :
          {...previousValue};

      default:
        return {...previousValue, [key]: params[key]};
    }
  }, {});
}

function parseCustomFields(key, params) {
  const customFields = params[key];
  return customFields?.reduce((res, item) => {
    res[item.id] = item.valueLike;
    return res;
  }, {});
}

/**
 * Inverse of parseFilterParams, test should be to run valid options through both and get same results as start
 */
export function parseParamsFilter(params, lookups) {
  if (_.isEmpty(params)) {
    return {};
  }

  const {addressLabels} = lookups;

  // Reverse parse for body of API request back to form filter shape
  return Object.keys(params).reduce((previousValue, key) => {
    switch (key) {
      case constants.PERSON_LOCATIONS:
      case constants.PERSON_OR_LOCATIONS_IDS:
        return {...previousValue, [key]: params[key]?.map(locationIdNumber => locationIdNumber.toString())};
      case constants.PERSON_ORGANIZATIONS:
        return {
          ...previousValue, [key]: params[key]
        };
      case constants.ADDRESS_LABELS:
        return {
          ...previousValue, [key]: params[key]?.map(valueId => {
            const foundValue = addressLabels.find(valueItem => valueItem.value === valueId);

            return foundValue ? foundValue : {
              ...ADDRESS_LABEL.NONE,
              label: localization.formatMessage(ADDRESS_LABEL.NONE.labelMessage)
            };
          })
        };
      case constants.ADDRESS_ACCURACY:
        return {
          ...previousValue, [key]: params[key]?.map(valueId => {
            const foundValue = addressAccuracies.find(accuracy => accuracy.value === valueId);

            return foundValue ? {
              ...foundValue,
              label: localization.formatMessage(foundValue.labelMessage)
            } : {
              ...ADDRESS_LABEL.NONE,
              label: localization.formatMessage(ADDRESS_ACCURACY.UNKNOWN.labelMessage)
            };
          })
        };
      case constants.PERSON_STATUS:
        return {
          ...previousValue,
          [key]: _.chain(params)
            .get(key)
            .map(value => {
              if (value?.includes('DISABLED')) {
                return PERSON_STATUSES.DISABLED;
              }
              return PERSON_STATUSES[value];
            })
            .uniq()
            .value()
        };
      case 'dateEnd':
        return {...previousValue, [constants.PERSON_DATE_RANGE]: [moment.utc(params['dateStart']), moment.utc(params['dateEnd'])]};
      case 'dateStart': // return previous because end date parsing handles it
        return previousValue;
      case `${constants.PERSON_FIRSTNAME}Like`:
      case `${constants.PERSON_LASTNAME}Like`:
      case `${constants.PERSON_MIDDLENAME}Like`:
      case `${constants.EXPATRIATION_NAME}Like`:
        return {
          ...previousValue,
          [key.replace('Like', '')]: params[key]
        };
      case 'status':
        return {
          ...previousValue,
          [constants.SHOW_DELETED_PEOPLE_ONLY]: params['status'] === PERSON_STATUS.DELETED.value
        };
      case 'itineraryDeletedStatuses':
        return {
          ...previousValue,
          [constants.SHOW_DELETED_TRIPS]: _.isEqual(params[key], [TRIP_DELETED_STATUS.ACTIVE.value, TRIP_DELETED_STATUS.DELETED.value])
        };
      case 'expatriationDeletedStatuses':
        return {
          ...previousValue,
          [constants.SHOW_DELETED_EXPATRIATIONS]: _.isEqual(params[key], [EXPAT_DELETED_STATUS.ACTIVE.value, EXPAT_DELETED_STATUS.DELETED.value])
        };
      case constants.PERSON_LOCATION_TYPES:
        return {
          ...previousValue,
          [key]: {
            [PERSON_GROUP]: params[key],
            [PERSON_MOBILE_LOCATIONS_RADIO]: params[constants.MOBILE_LOCATION_SCOPE] === ALL ? ML_ALL : ML_LAST_ONLY
          }
        };
      case 'excludePersonTypes':
      case 'personTypes':
        return {
          ...previousValue,
          [constants.PERSON_TYPES]: {
            include: key,
            types: params[key]
          }
        };
      case constants.ITINERARY_NAME:
      case constants.RECORD_LOCATOR:
      case AIRLINE_ID:
      case FLIGHT_NUMBER_LIKE:
      case AIRPORT_ID:
      case constants.HOTEL_NAME:
      case constants.TRIP_NOTES:
      case constants.SITE_IDS:
        return {...previousValue, [key]: params[key]};
      case 'siteAddressLike':
        return {...previousValue, [constants.SITE_ADDRESS]: params[key]};
      case 'mobileLocationScope':
        return previousValue;
      case constants.CUSTOM_FIELDS:
        return {...previousValue, [constants.CUSTOM_FIELDS]: parseCustomFields(key, params)};
      default:
        return {...previousValue, [key]: params[key]};
    }
  }, {});
}

export function processDownloadData(downloadDataObject = {}) {

  //Set specific data options based on corresponding 'included' property
  //Do not add 'included' properties to returned object
  return Object.entries(downloadDataObject).reduce((previousValue, [key, value]) => {
    switch (key) {
      case 'accountInfo':
      case 'addresses':
      case 'emails':
      case 'phones':
      case 'emergencyContacts':
      case 'identifiers':
      case 'names':
      case 'mobileLocations':
      case 'tripSegments':
        return {
          ...previousValue,
          [key]: downloadDataObject[[key] + 'Included'] ? value : PERSON_EXPORT_DATA_OPTIONS.EXCLUDE
        };
      case 'includeOrganizations':
      case 'includePersonTypes':
      case 'optionalFields':
      case 'includeExpatriations':
      case 'includeSites':
        return {...previousValue, [key]: value};
      default:
        return {...previousValue};
    }
  }, {});
}

/**
 * Returns the local storage object for 'person-search-download-data' key
 *
 * @returns {*}
 */
export function getDownloadDataLocalStorage() {
  let downloadDataLocalStorage = {};
  try {
    downloadDataLocalStorage = JSON.parse(horizonLocalStorage.getItem('person-search-download-data'));
  }
  catch (e) {
    // do nothing, variable will get set below
  }
  return getExportDetailsDefault(downloadDataLocalStorage);
}

export function getExportDetailsDefault(values) {
  return {
    [PERSON_EXPORT_DATA_FIELD_NAMES.NAMES_INCLUDED]: _.get(values,
      PERSON_EXPORT_DATA_FIELD_NAMES.NAMES_INCLUDED, true),
    names: _.get(values, 'names', PERSON_EXPORT_DATA_OPTIONS.PRIMARY),
    includeOrganizations: _.get(values, 'includeOrganizations', true),
    includePersonTypes: _.get(values, 'includePersonTypes', false),
    optionalFields: _.get(values, 'optionalFields', false),
    [PERSON_EXPORT_DATA_FIELD_NAMES.ACCOUNT_INFO_INCLUDED]: _.get(values,
      PERSON_EXPORT_DATA_FIELD_NAMES.ACCOUNT_INFO_INCLUDED, true),
    accountInfo: _.get(values, 'accountInfo', PERSON_EXPORT_DATA_OPTIONS.PRIMARY),
    [PERSON_EXPORT_DATA_FIELD_NAMES.EMAILS_INCLUDED]: _.get(values,
      PERSON_EXPORT_DATA_FIELD_NAMES.EMAILS_INCLUDED, true),
    emails: _.get(values, 'emails', PERSON_EXPORT_DATA_OPTIONS.PRIMARY),
    [PERSON_EXPORT_DATA_FIELD_NAMES.PHONES_INCLUDED]: _.get(values,
      PERSON_EXPORT_DATA_FIELD_NAMES.PHONES_INCLUDED, true),
    phones: _.get(values, 'phones', PERSON_EXPORT_DATA_OPTIONS.PRIMARY),
    [PERSON_EXPORT_DATA_FIELD_NAMES.EMERGENCY_CONTACTS_INCLUDED]: _.get(values,
      PERSON_EXPORT_DATA_FIELD_NAMES.EMERGENCY_CONTACTS_INCLUDED, true),
    emergencyContacts: _.get(values, 'emergencyContacts', PERSON_EXPORT_DATA_OPTIONS.PRIMARY),
    [PERSON_EXPORT_DATA_FIELD_NAMES.IDENTIFIERS_INCLUDED]: _.get(values,
      PERSON_EXPORT_DATA_FIELD_NAMES.IDENTIFIERS_INCLUDED, true),
    identifiers: _.get(values, 'identifiers', PERSON_EXPORT_DATA_OPTIONS.PRIMARY),
    [PERSON_EXPORT_DATA_FIELD_NAMES.ADDRESSES_INCLUDED]: _.get(values,
      PERSON_EXPORT_DATA_FIELD_NAMES.ADDRESSES_INCLUDED, true),
    addresses: _.get(values, 'addresses', PERSON_EXPORT_DATA_OPTIONS.PRIMARY),
    [PERSON_EXPORT_DATA_FIELD_NAMES.MOBILE_LOCATIONS_INCLUDED]: _.get(values,
      PERSON_EXPORT_DATA_FIELD_NAMES.MOBILE_LOCATIONS_INCLUDED, false),
    mobileLocations: _.get(values, 'mobileLocations', PERSON_EXPORT_DATA_OPTIONS.LAST),
    [PERSON_EXPORT_DATA_FIELD_NAMES.TRIP_SEGMENTS_INCLUDED]: _.get(values,
      PERSON_EXPORT_DATA_FIELD_NAMES.TRIP_SEGMENTS_INCLUDED, false),
    tripSegments: _.get(values, 'tripSegments', PERSON_EXPORT_DATA_OPTIONS.ALL),
    [PERSON_EXPORT_DATA_FIELD_NAMES.EXPATRIATIONS_INCLUDED]: _.get(values,
      PERSON_EXPORT_DATA_FIELD_NAMES.EXPATRIATIONS_INCLUDED, false),
    includeSites: _.get(values, 'includeSites', PERSON_EXPORT_DATA_OPTIONS.ALL),
    [PERSON_EXPORT_DATA_FIELD_NAMES.SITE_DETAILS_INCLUDED]: _.get(values,
      PERSON_EXPORT_DATA_FIELD_NAMES.SITE_DETAILS_INCLUDED, false)
  };
}

/**
 * Sets the local storage object for 'person-search-download-data' key
 *
 * @returns {*}
 */
export function setDownloadDataLocalStorage(storageObject) {
  horizonLocalStorage.setItem('person-search-download-data', JSON.stringify(storageObject));
}

//https://bitbucket.org/crisis24/wc4-api-docs/src/4865e37a178eb8074ff7225273f7e5c3fb761c8e/src/docs/private/alsat/wc4-alsat-ms.yaml#lines-1891
// Jul 21 2023
// Verify user and need for these value to make sure constants are correctly organized
const ADDRESS_ACCURACY = {
  UNKNOWN: {
    value: 'UNKNOWN',
    labelMessage: addressAccuracyMessages.unknown
  },
  CITY: {
    value: 'CITY',
    labelMessage: addressAccuracyMessages.city
  },
  COUNTRY: {
    value: 'COUNTRY',
    labelMessage: addressAccuracyMessages.country
  },
  POSTAL_CODE: {
    value: 'POSTAL_CODE',
    labelMessage: addressAccuracyMessages.zipPostalCode
  },
  STATE_PROVINCE_TERRITORIES: {
    value: 'STATE_PROVINCE_TERRITORIES',
    labelMessage: addressAccuracyMessages.provinceState
  },
  STREET_ADDRESS: {
    value: 'STREET_ADDRESS',
    labelMessage: addressAccuracyMessages.street
  },
  USER_PROVIDED: {
    value: 'USER_PROVIDED',
    labelMessage: addressAccuracyMessages.userProvided
  },
  NOT_GEOCODED: {
    value: 'NOT_GEOCODED',
    labelMessage: addressAccuracyMessages.notGeocoded
  }
};

// TODO these values are service driven except for "NONE", will need to review this logic for populating data backwards
const ADDRESS_LABEL = {
  NONE: {
    value: 'NONE',
    labelMessage: addressLabelMessages.none
  }
};

const EXPAT_DELETED_STATUS = {
  UNKNOWN: {
    value: 'UNKNOWN'
  },
  ACTIVE: {
    value: 'ACTIVE'
  },
  DELETED: {
    value: 'DELETED'
  }
};

const TRIP_DELETED_STATUS = {
  UNKNOWN: {
    value: 'UNKNOWN'
  },
  ACTIVE: {
    value: 'ACTIVE'
  },
  DELETED: {
    value: 'DELETED'
  }
};

const TRIP_STATUS = {
  BOOKED: {
    value: 'BOOKED'
  },
  CANCELLED: {
    value: 'CANCELLED'
  }
};

const MOBILE_LOCATION_SCOPE = {
  UNKNOWN: {
    value: 'UNKNOWN'
  },
  ALL: {
    value: 'ALL'
  },
  LAST: {
    value: 'LAST'
  },
  EXCLUDE: {
    value: 'EXCLUDE'
  }
};

export const PERSON_LOCATION_TYPE = {
  UNKNOWN: {
    value: 'UNKNOWN'
  },
  MOBILE_BACKGROUND_SHARE: {
    value: 'MOBILE_BACKGROUND_SHARE'
  },
  CRISIS_SIGNAL: {
    value: 'CRISIS_SIGNAL'
  },
  SAFETY_CHECK_IN: {
    value: 'SAFETY_CHECK_IN'
  },
  TRIP_AIR: {
    value: 'TRIP_AIR',
    requiredAggregatedPermission: VIEW_TRIPS
  },
  TRIP_HOTEL: {
    value: 'TRIP_HOTEL',
    requiredAggregatedPermission: VIEW_TRIPS
  },
  ADDRESS: {
    value: 'ADDRESS',
    requiredAggregatedPermission: VIEW_TRIPS
  },
  TRIP_RAIL: {
    value: 'TRIP_RAIL',
    requiredAggregatedPermission: VIEW_TRIPS
  },
  TRIP_CAR: {
    value: 'TRIP_CAR',
    requiredAggregatedPermission: VIEW_TRIPS
  },
  EXPATRIATION: {
    value: 'EXPATRIATION',
    requiredAggregatedPermission: VIEW_EXPATRIATIONS
  },
  POC: {
    value: 'POC',
    requiredAggregatedPermission: VIEW_SITES
  },
  OCCUPANT: {
    value: 'OCCUPANT',
    requiredAggregatedPermission: VIEW_SITES
  }
};

const PROFILE_STATUS = {
  INCOMPLETE: {
    value: 'INCOMPLETE'
  },
  COMPLETE: {
    value: 'COMPLETE'
  },
  DUPLICATE: {
    value: 'DUPLICATE'
  }
};

const PERSON_STATUS = {
  UNKNOWN: {
    value: 'UNKNOWN'
  },
  ACTIVE: {
    value: 'ACTIVE'
  },
  DELETED: {
    value: 'DELETED',
    requiredAggregatedPermission: VIEW_DELETED_PERSONS
  }
};

const USER_STATUS = {
  NO_ACCOUNT: {
    value: 'NO_ACCOUNT'
  },
  INACTIVE: {
    value: 'INACTIVE'
  },
  ACTIVE: {
    value: 'ACTIVE'
  },
  INACTIVE_DISABLED: {
    value: 'INACTIVE_DISABLED'
  },
  ACTIVE_DISABLED: {
    value: 'ACTIVE_DISABLED'
  },
  DISABLED_DELETED: {
    value: 'DISABLED_DELETED'
  },
  UNKNOWN: {
    value: 'UNKNOWN'
  }
};

// ONE OF LOGIC ... user is expected to have view persons and one or the location type access
const ONE_OF_PERSON_LOCATION_TYPES_PERMISSION_LOGIC = {
  requiredAggregatedPermissions: [VIEW_PERSONS],
  anyOfAggregatedPermissions: [
    VIEW_SAFETY_CHECKIN_SIGNAL_LOCATIONS,
    VIEW_CRISIS_SIGNAL_LOCATIONS,
    VIEW_PERSONS_MOBILE_LOCATION,
    VIEW_TRIPS,
    VIEW_EXPATRIATIONS,
    VIEW_PERSONS_GEOCODED_ADDRESS,
    VIEW_SITES
  ]
};

export const PERSON_SEARCH_FILTER_FIELD = {
  ADDRESS_ACCURACIES: {
    value: constants.ADDRESS_ACCURACY,
    valuesDict: ADDRESS_ACCURACY,
    requiredAggregatedPermission: VIEW_PERSONS_GEOCODED_ADDRESS
  },
  ADDRESS_LABELS: {     // service driven values except for "NONE" value
    value: constants.ADDRESS_LABELS,
    requiredAggregatedPermission: VIEW_PERSONS_GEOCODED_ADDRESS
  },
  AIRLINE_ID: {
    value: 'airlineId',
    requiredAggregatedPermission: VIEW_TRIPS
  },
  AIRPORT_ID: {
    value: 'airportId',
    requiredAggregatedPermission: VIEW_TRIPS
  },
  CLUSTER: {
    value: 'cluster'
  },
  SUPERVISOR: {
    value: 'supervisor',
  },
  LOCATION_CODE: {
    value: 'locationCode',
  },
  RESPONSIBLE_SECURITY_OFFICER: {
    value: 'responsibleSecurityOfficer',
  },
  REGION: {
    value: 'region',
  },
  BUSINESS_UNIT: {
    value: 'businessUnit',
  },
  DATE_END: {
    value: constants.PERSON_DATE_END,
    ...ONE_OF_PERSON_LOCATION_TYPES_PERMISSION_LOGIC
  },
  DATE_RANGES: {
    value: 'dateRanges',
    ...ONE_OF_PERSON_LOCATION_TYPES_PERMISSION_LOGIC
  },
  DATE_START: {
    value: constants.PERSON_DATE_START,
    ...ONE_OF_PERSON_LOCATION_TYPES_PERMISSION_LOGIC
  },
  EXCLUDES_PERSON_TYPES: {
    value: constants.PERSON_EXCLUDES_TYPES,
    requiredAggregatedPermission: VIEW_PERSON_TYPES
  },
  EXPAT_DELETED_STATUSES: {
    value: 'expatriationDeletedStatuses',
    valuesDict: EXPAT_DELETED_STATUS,
    requiredAggregatedPermission: VIEW_EXPATRIATIONS
  },
  EXPAT_NAME_LIKE: {
    value: 'expatriationNameLike',
    requiredAggregatedPermission: VIEW_EXPATRIATIONS
  },
  FIRST_NAME_LIKE: {
    value: 'firstNameLike'
  },
  FLIGHT_NUMBER_LIKE: {
    value: 'flightNumberLike',
    requiredAggregatedPermission: VIEW_TRIPS
  },
  TRIP_DELETED_STATUSES: {
    value: 'itineraryDeletedStatuses',
    valuesDict: TRIP_DELETED_STATUS
  },
  TRIP_NAME_LIKE: {
    value: 'itineraryNameLike',
    requiredAggregatedPermission: VIEW_TRIPS
  },
  TRIP_NOTES_LIKE: {
    value: 'itineraryNotesLike',
    requiredAggregatedPermission: VIEW_TRIPS
  },
  TRIP_RECORD_LOCATION_LIKE: {
    value: 'itineraryRecordLocatorLike',
    requiredAggregatedPermission: VIEW_TRIPS
  },
  TRIP_STATUSES: {
    value: 'itineraryStatuses',
    valuesDict: TRIP_STATUS,
    requiredAggregatedPermission: VIEW_TRIPS
  },
  LAST_NAME_LIKE: {
    value: 'lastNameLike'
  },
  LOCATION_IDS: {
    value: 'locationIds',
    ...ONE_OF_PERSON_LOCATION_TYPES_PERMISSION_LOGIC
  },
  MIDDLE_NAME_LIKE: {
    value: 'middleNameLike'
  },
  MOBILE_LOCATION_SCOPE: {
    value: 'mobileLocationScope',
    valuesDict: MOBILE_LOCATION_SCOPE,
    ...ONE_OF_PERSON_LOCATION_TYPES_PERMISSION_LOGIC
  },
  OR_LOCATION_IDS: {
    value: 'orLocationIds',
    ...ONE_OF_PERSON_LOCATION_TYPES_PERMISSION_LOGIC
  },
  ORGANIZATION_IDS: {
    value: 'organizationIds'
  },
  PERSON_IDS: {
    value: 'personIds'
  },
  PERSON_LOCATION_IDS: {
    value: 'personLocationIds',
    ...ONE_OF_PERSON_LOCATION_TYPES_PERMISSION_LOGIC
  },
  PERSON_LOCATION_TYPES: {
    value: 'personLocationTypes',
    valuesDict: PERSON_LOCATION_TYPE,
    ...ONE_OF_PERSON_LOCATION_TYPES_PERMISSION_LOGIC
  },
  PERSON_TYPES: {
    value: 'personTypes',
    requiredAggregatedPermission: VIEW_PERSON_TYPES
  },
  POLYGONS: {
    value: 'polygons',
    ...ONE_OF_PERSON_LOCATION_TYPES_PERMISSION_LOGIC
  },
  PROFILE_STATUSES: {
    value: 'profileStatuses',
    valuesDict: PROFILE_STATUS
  },
  QUICK_SEARCH_TEXT: {
    value: 'quickSearchText'
  },
  RAIL_WAY_NAME_LIKE: {
    value: 'railwayNameLike',
    requiredAggregatedPermission: VIEW_TRIPS
  },
  SEARCH_TEXT: {
    value: 'searchText'
  },
  SITE_ADDRESS_LIKE: {
    value: 'siteAddressLike',
    requiredAggregatedPermission: VIEW_SITES
  },
  SITE_IDS: {
    value: 'siteIds',
    requiredAggregatedPermission: VIEW_SITES
  },
  SITE_TYPE_IDS: {
    value: 'siteTypeIds',
    requiredAggregatedPermission: VIEW_SITES
  },
  STATUS: {
    value: 'status',
    valuesDict: PERSON_STATUS,
    requiredAggregatedPermission: VIEW_DELETED_PERSONS
  },
  TRAIN_NUMBER_LIKE: {
    value: 'trainNumberLike',
    requiredAggregatedPermission: VIEW_TRIPS
  },
  USER_STATUSES: {
    value: 'userStatuses',
    valuesDict: USER_STATUS
  },
  CUSTOM_FIELDS: {
    value: 'customFields'
  },
  HOTEL_NAME: {
    value: 'hotelNameLike'
  },
  ///////////
  // UI SIDE ONLY PROPERTIES
  ///////////
  PERSON_DATE_RANGE: {
    value: 'personDateRange',
    ...ONE_OF_PERSON_LOCATION_TYPES_PERMISSION_LOGIC
  },
  SHOW_DELETED_PEOPLE_ONLY: {
    value: constants.SHOW_DELETED_PEOPLE_ONLY
  },
  FIRST_NAME: {
    value: constants.PERSON_FIRSTNAME
  },
  LAST_NAME: {
    value: constants.PERSON_LASTNAME
  },
  EXPAT_NAME: {
    value: constants.EXPATRIATION_NAME,
    requiredAggregatedPermission: VIEW_EXPATRIATIONS
  },
  SITE_ADDRESS: {
    value: constants.SITE_ADDRESS,
    requiredAggregatedPermission: VIEW_SITES
  },
  SHOW_DELETED_EXPATRIATIONS: {
    value: constants.SHOW_DELETED_EXPATRIATIONS,
    requiredAggregatedPermission: VIEW_EXPATRIATIONS
  },
  SHOW_DELETED_TRIPS: {
    value: constants.SHOW_DELETED_TRIPS,
    requiredAggregatedPermission: VIEW_TRIPS
  },
  PERSON_IDENTIFIER: {
    value: constants.PERSON_IDENTIFIER
  },
};

const personSearchFilterFields = _.values(PERSON_SEARCH_FILTER_FIELD);
const personLocationTypes = _.values(PERSON_LOCATION_TYPE);
const addressAccuracies = _.values(ADDRESS_ACCURACY);

export function parseRegardingPermissions(params, aggregatedPermissions, lookups) {
  const reversedParams = parseParamsFilter(params, lookups);
  const {organizationDict} = lookups;

  return _.reduce(reversedParams, async (acc, value, key) => {
    let hasError;
    const field = personSearchFilterFields.find(field => key === field?.value);

    if (!field) {
      console.error(`field ${key} unknown`);
      return acc;
    }
    else {
      const {requiredAggregatedPermission, requiredAggregatedPermissions, anyOfAggregatedPermissions} = field;

      const hasRequiredAggregatedPermission = !requiredAggregatedPermission || aggregatedPermissions[requiredAggregatedPermission];
      const hasRequiredAggregatedPermissions =
        !requiredAggregatedPermissions || _.every(requiredAggregatedPermissions, permission => aggregatedPermissions[permission]);
      const hasAnyOfAggregatedPermissions =
        !anyOfAggregatedPermissions || _.some(anyOfAggregatedPermissions, permission => aggregatedPermissions[permission]);

      const hasAccessToField = hasRequiredAggregatedPermission && hasRequiredAggregatedPermissions && hasAnyOfAggregatedPermissions;

      if (field === PERSON_SEARCH_FILTER_FIELD.PERSON_LOCATION_TYPES) {
        const filteredValue = _.reduce(value['personGroup'], (valuesAcc, valueItem) => {
          const locationType = personLocationTypes.find(type => valueItem === type?.value);

          const locationTypePermission = locationType?.requiredAggregatedPermission;

          const hasLocationTypeRequiredPermission = !locationTypePermission || aggregatedPermissions[locationTypePermission];

          if (hasLocationTypeRequiredPermission) {
            valuesAcc.push(valueItem);
          }
          else {
            hasError = true;
          }

          return valuesAcc;
        }, []);

        if (!hasAccessToField) {
          hasError = true;
        }
        return hasAccessToField
          ? acc.then(result => ({...result, [key]: {...value, 'personGroup': filteredValue}, hasError: result.hasError || hasError}))
          : acc;
      }

      if (field === PERSON_SEARCH_FILTER_FIELD.ORGANIZATION_IDS) {
        const organizations = value?.map(valueId => {
          const organization = organizationDict[valueId];
          return organization.label ? organization : null;
        });

        if (_.some(organizations, org => org === null)) {
          hasError = true;
        }

        return acc.then(result => ({...result, [key]: _.compact(organizations), hasError: result.hasError || hasError}));
      }

      if (field === PERSON_SEARCH_FILTER_FIELD.SITE_IDS) {
        const sitesList = await sitesApi.getSitesSearchList({params: {size: 1000}, data: {siteIds: value}})
          .then(response => response?.data?.content?.map(site => ({value: site.id, label: site.name})));

        if (sitesList?.length !== value?.length) {
          hasError = true;
        }

        return acc.then(result => ({...result, [key]: sitesList, hasError: result.hasError || hasError}));
      }

      if (field === PERSON_SEARCH_FILTER_FIELD.AIRLINE_ID) {
        const airlineInfo = await airlineApi.airlineSearchList({params: {airlineId: value}})
          .then(response => {
            const {iataCode, name, id} = response?.data?.content?.[0] || {};
            const labelValue = `(${iataCode}) ${name}`;
            return {
              code: iataCode,
              id: id,
              label: labelValue,
              value: labelValue
            };
          });

        if (!airlineInfo) {
          hasError = true;
        }

        return acc.then(result => ({...result, [key]: airlineInfo, hasError: result.hasError || hasError}));
      }

      if (field === PERSON_SEARCH_FILTER_FIELD.AIRPORT_ID) {
        const airportInfo = await geoLocationsApi.getAirportDetails({pathParams: {id: value}})
          .then(response => {
            const {iata, name, id} = response?.data || {};
            const labelValue = `(${iata}) ${name}`;
            return {
              iata: iata,
              id: id.toString(),
              label: labelValue,
              value: labelValue
            };
          });

        if (!airportInfo) {
          hasError = true;
        }

        return acc.then(result => ({...result, [key]: airportInfo, hasError: result.hasError || hasError}));
      }

      if (!hasAccessToField) {
        hasError = true;
      }

      return acc.then(result => ({...result, [key]: value, hasError: result.hasError || hasError}));
    }
  }, Promise.resolve({}));
}
