import _ from 'lodash';
import wellknown from 'wellknown';
import {defineMessages} from 'react-intl';
import {bbox} from '@turf/turf';

import {
  VIEW_CAUTION_ALERTS,
  VIEW_CRITICAL_ALERTS,
  VIEW_INCIDENTS,
  VIEW_INFORMATIONAL_ALERTS,
  VIEW_WARNING_ALERTS
} from '../../../../constants/permissions';
import {
  ALERT_CATEGORIES,
  ALERT_REQUIRE_EXPOSURE,
  ALERT_SEVERITIES,
  INCIDENT_IMPACT_LEVELS,
  SITE_REQUIRE_EXPOSURE,
  WORLD_MAP_FILTER_STORAGE_KEY_BASE
} from '../../../../constants/alert-filters';
import {ALERT_LAYER_ID, MAP_LOCATIONS_FILTER} from '../../../../constants/map';
import {ALERT_LAYERS} from '../../../../components/Common/Mapbox/layers/layerIds';
import {formExposureBoundAndDataSource} from '../../alertDetails/utils';
import {getMapSelectedPeopleOrgs} from '../utils';
import mapboxUtils, {getBoundsFromFeature} from '../../../../components/Common/Mapbox/utils/mapbox-utils';
import {INCIDENT_IMPACT_LEVEL, INCIDENT_IMPACT_LEVEL_TO_SEVERITY, INCIDENT_IMPACT_LEVEL_KEY} from '../../../../constants/alert-severity-keys';
import localization from '../../../../services/localization';

export const SEVERITY_MAPPING = [
  {id: 'INFORMATIONAL', permission: VIEW_INFORMATIONAL_ALERTS, severityValue: 'Informational', severityKey: '0'},
  {id: 'CAUTION', permission: VIEW_CAUTION_ALERTS, severityValue: 'Caution', severityKey: '1'},
  {id: 'WARNING', permission: VIEW_WARNING_ALERTS, severityValue: 'Warning', severityKey: '2'},
  {id: 'CRITICAL', permission: VIEW_CRITICAL_ALERTS, severityValue: 'Critical', severityKey: '3'}
];

export const messagesAlertSeverities = defineMessages({
  CRITICAL: {
    id: 'alertSeveritiesUtils.CRITICAL',
    defaultMessage: 'Critical'
  },
  WARNING: {
    id: 'alertSeveritiesUtils.WARNING',
    defaultMessage: 'Warning'
  },
  CAUTION: {
    id: 'alertSeveritiesUtils.CAUTION',
    defaultMessage: 'Caution'
  },
  INFORMATIONAL: {
    id: 'alertSeveritiesUtils.INFORMATIONAL',
    defaultMessage: 'Informational'
  },
  title: {
    id: 'alertSeveritiesUtils.title',
    defaultMessage: 'Alerts'
  }
});

export const INCIDENT_MAPPING = [
  {
    id: 'SEVERE',
    permission: VIEW_INCIDENTS,
    severityValue: 'Severe',
    severityKey: INCIDENT_IMPACT_LEVEL_KEY[INCIDENT_IMPACT_LEVEL.SEVERE]
  },
  {
    id: 'HIGH',
    permission: VIEW_INCIDENTS,
    severityValue: 'High',
    severityKey: INCIDENT_IMPACT_LEVEL_KEY[INCIDENT_IMPACT_LEVEL.HIGH]
  },
  {
    id: 'MODERATE',
    permission: VIEW_INCIDENTS,
    severityValue: 'Moderate',
    severityKey: INCIDENT_IMPACT_LEVEL_KEY[INCIDENT_IMPACT_LEVEL.MODERATE]
  },
  {
    id: 'LOW',
    permission: VIEW_INCIDENTS,
    severityValue: 'Low',
    severityKey: INCIDENT_IMPACT_LEVEL_KEY[INCIDENT_IMPACT_LEVEL.LOW]
  }
];

export const messagesIncidentSeverities = defineMessages({
  SEVERE: {
    id: 'incidentSeveritiesUtils.SEVERE',
    defaultMessage: 'Severe'
  },
  HIGH: {
    id: 'incidentSeveritiesUtils.HIGH',
    defaultMessage: 'High'
  },
  MODERATE: {
    id: 'incidentSeveritiesUtils.MODERATE',
    defaultMessage: 'Moderate'
  },
  LOW: {
    id: 'incidentSeveritiesUtils.LOW',
    defaultMessage: 'Low'
  },
  MINIMAL: {
    id: 'incidentSeveritiesUtils.MINIMAL',
    defaultMessage: 'Minimal'
  },
  title: {
    id: 'incidentSeveritiesUtils.title',
    defaultMessage: 'Incidents'
  }
});

export function generateDetailFeature(alert) {
  function getBounds() {
    const geometry = {
      geometry: geMapCenterPointFromAlert(alert)
    };

    return mapboxUtils.getBoundsFromFeature(geometry);
  }

  return {
    bounds: getBounds(),
    layer: {id: 'alerts-layer'},
    properties: getPropertiesFromAlert(alert)
  };
}

/**
 * Combines a map alert feature with its polygon results
 * to create an alert polygon feature.
 *
 * @param feature
 * @param result
 */
export function parseAlertPolygonResult(feature, result) {
  const shape = _.get(result, 'data.shape');
  const geometry = wellknown.parse(shape);
  const bounds = mapboxUtils.getBoundingBox(geometry);

  return {
    type: 'Feature',
    properties: feature.properties,
    geometry: geometry,
    bounds: bounds
  };
}

/**
 * Returns an object conforming to API for filtering alerts based on current severity and category filters
 * @param state
 * @return {{activeWithinEnd: undefined, categoryIds: *[], activeWithinStart: undefined, organizationIds: string[], locationIds: undefined, includeExpired: undefined, alertSeverities: *[]}}
 */
export function createAlertsFilterFromState(state) {
  const alertSeverities = _.get(state, ['worldMap', WORLD_MAP_FILTER_STORAGE_KEY_BASE, ALERT_SEVERITIES], [])
    .map(severityValue => SEVERITY_MAPPING.find(item => item.severityValue === severityValue).id);
  const incidentImpactLevels = _.get(state, ['worldMap', WORLD_MAP_FILTER_STORAGE_KEY_BASE, INCIDENT_IMPACT_LEVELS], [])
    .map(severityValue => INCIDENT_MAPPING.find(item => item.severityValue === severityValue).id);
  const categoryIds = _.get(state, ['worldMap', WORLD_MAP_FILTER_STORAGE_KEY_BASE, ALERT_CATEGORIES], []);
  const includeExpired = _.get(state, ['worldMap', 'includeExpiredIntelligence']);
  const locationIds = _.get(state, ['worldMap', MAP_LOCATIONS_FILTER]);
  const activeWithinStart = _.get(state, ['worldMap', 'beginDateTime']);
  const activeWithinEnd = _.get(state, ['worldMap', 'endDateTime']);

  const requirePersonExposures = _.get(state, ['worldMap', WORLD_MAP_FILTER_STORAGE_KEY_BASE, ALERT_REQUIRE_EXPOSURE],
    false);

  const requireSiteExposures = _.get(state, ['worldMap', WORLD_MAP_FILTER_STORAGE_KEY_BASE, SITE_REQUIRE_EXPOSURE],
    false);

  const organizationIds = getMapSelectedPeopleOrgs(state);

  return {
    includeExpired,
    alertSeverities,
    incidentImpactLevels,
    categoryIds,
    locationIds,
    activeWithinStart,
    activeWithinEnd,
    organizationIds,
    requirePersonExposures,
    requireSiteExposures
  };
}

export function geMapCenterPointFromAlert(alert) {
  const center = alert.mapData?.center || {};
  return {
    type: 'Point',
    coordinates: [center.lng, center.lat]
  };
}

function buildSeverityKey(item) {
  if (item.alertId) {
    return SEVERITY_MAPPING.find(i => i.id === item.alertSeverity)?.severityKey;
  } else if (item.incidentId) {
    return INCIDENT_MAPPING.find(i => i.id === item.incidentImpactLevel)?.severityKey;
  }
}

export function getPropertiesFromAlert(item) {
  return {
    id: item.alertId || item.incidentId,
    fullTitle: item.title,
    severityKey: buildSeverityKey(item),
    modifiedDate: item.modifiedDate,
    publishedDate: item.publishedDate,
    geoJsonUrl: item.mapData?.geoJsonUrl,
    intelType: item?.intelType
  };
}

export function parseIntelAlertsResults(results) {
  return results?.data?.content?.map(alert => {

    return {
      type: 'Feature',
      geometry: geMapCenterPointFromAlert(alert),
      properties: getPropertiesFromAlert(alert)
    };
  });
}

export function parseAlertsPolygonFeatureResult(response) {
  response?.data?.content?.sort((a, b) => (b?.severity?.key.localeCompare(a?.severity?.key)));
  const features = parseIntelAlertsResults(response) || [];
  return features.map(feature => ({...feature, layer: {id: ALERT_LAYER_ID}}));
}

export function mergeGeoJsonAndAlertDetailsResponses([geoJsonUrlResponse, alertDetailsResponse, exposuresDetailsResponse]) {
  const alertDetails = alertDetailsResponse?.value?.data;
  const dataSource = geoJsonUrlResponse?.value?.data;
  const exposureSummary = exposuresDetailsResponse?.value?.data;

  if (dataSource) {
    dataSource.features?.forEach(feature => {
      feature.bounds = getBoundsFromFeature(feature);
      feature.properties.severity = alertDetails.severity.key;
    });

    alertDetails.parsedMapData = {
      dataSource,
      bounds: getBoundsFromFeature(dataSource)
    };
  }
  else {
    alertDetails.parsedMapData = formExposureBoundAndDataSource(alertDetails);
  }

  if (exposureSummary) {
    alertDetails.exposures = exposureSummary?.exposures;
  }

  return alertDetails;
}

/**
 * Returns true if ALERT_LAYERS includes the feature layer.id
 *
 * @param feature
 * @returns {*}
 */
export function isAlertFeature(feature) {
  const layerId = _.get(feature, 'layer.id');
  return _.includes(ALERT_LAYERS, layerId);
}

export function isAllAlertLayersTurnedOn(permissionMap, alertFilter) {
  return _.chain(SEVERITY_MAPPING)
    .filter(d => permissionMap[d.permission])
    .map(d => d.severityValue)
    .thru(value => value.sort())
    .isEqual(alertFilter?.severities?.sort())
    .value();
}

export function filterSeveritiesViaPermissions(hasPermission = {}, severities, mapping) {
  return mapping.reduce((accumulator, permissionSeverity) => {
    if (severities?.includes?.(permissionSeverity.severityValue) && hasPermission[permissionSeverity.permission]) {
      accumulator.push(permissionSeverity.severityValue);
    }
    return accumulator;
  }, []);
}

export function getSeverityMappinsValues() {
  return SEVERITY_MAPPING.map(d => d.severityValue);
}

const messagesIncidentFeatures = defineMessages({
  affectedArea: {
    id: 'messagesIncidentFeatures.affectedArea',
    defaultMessage: 'Affected Area'
  },
  incidentLocation: {
    id: 'messagesIncidentFeatures.incidentLocation',
    defaultMessage: 'Incident Location'
  }
});

export function getIncidentMapParsedData(incidentDetails) {
  const bounds = bbox(incidentDetails?.geometry);
  const features = [];
  if (incidentDetails?.geometry) {
    features.push(
      {
        geometry: incidentDetails?.geometry,
        properties: {
          'feature-description':  localization.formatMessage(messagesIncidentFeatures.affectedArea),
          'feature-id': incidentDetails?.id,
          'feature-impact': incidentDetails?.impactLevel,
          'feature-title': incidentDetails?.name,
          'severity': INCIDENT_IMPACT_LEVEL_TO_SEVERITY[incidentDetails?.impactLevel]
        },
        bounds
      }
    );
  }
  if (incidentDetails?.marker) {
    const markerBounds = bbox(incidentDetails?.marker);
    features.push(
      {
        geometry: incidentDetails?.marker,
        properties: {
          'feature-description':  localization.formatMessage(messagesIncidentFeatures.incidentLocation),
          'feature-id': incidentDetails?.id,
          'feature-symbol': "alertdetails_pointofinterest"
        },
        bounds: markerBounds
      }
    );
  }
  return {
    dataSource: {
      type: 'FeatureCollection',
      features
    },
    bounds
  };
}