/**
 *
 *
 *
 *   EDITING THIS FILE REQUIRES EDITS TO EMAIL TEMPLATE PROJECT REPO FILE AS WELL TO KEEP THEM IN SYNC
 *
 *
 *
 *
 */


import {LOCALES} from '../../../../../../../constants/locale';

export const PRETRAVEL_TEMPLATE_DEFAULTS = {
  [LOCALES.EN]: {
    subject: 'Pre Travel Notification',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Pre Travel Notification'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>You are scheduled to travel to the below destination(s). Please see the latest Travel Advice for these destinations along with any active alerts.</div><div><br></div><div>You are receiving this notification because your organization has opted you into receiving pre travel notifications. For further questions about this notification please contact your organization’s travel risk management team or submit a request for information to <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a>.</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  },
  [LOCALES.FR]: {
    subject: "Notification préalable au voyage",
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': "Notification préalable au voyage"
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Vous devez vous rendre à la ou aux destinations ci-dessous. Vous trouverez ci-dessous les derniers conseils aux voyageurs pour ces destinations ainsi que les alertes actives.</div><div><br></div><div>Vous recevez cette notification parce que votre organisation a choisi de recevoir des notifications avant le voyage. Pour toute autre question concernant cette notification, veuillez contacter l\'équipe de gestion des risques liés aux voyages de votre organisation ou envoyer une demande d\'informations à <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a>.</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  },
  [LOCALES.ES]: {
    subject: 'Notificación previa al viaje',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Notificación previa al viaje'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Tiene previsto viajar a los siguientes destinos. A continuación encontrará los consejos de viaje más recientes para estos destinos junto con las alertas activas.</div><div><br></div><div>Está recibiendo esta notificación porque su organización ha optado por que reciba notificaciones previas al viaje. Si tienes más preguntas sobre esta notificación, ponte en contacto con el equipo de gestión de riesgos de viaje de tu organización o envía una solicitud de información a <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a>.</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  },
  [LOCALES.DE]: {
    subject: 'Benachrichtigung vor Reiseantritt',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Benachrichtigung vor Reiseantritt'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Es ist geplant, dass Sie zu den unten aufgeführten Zielen reisen. Nachfolgend finden Sie die neuesten Reisehinweise für diese Reiseziele sowie alle aktiven Benachrichtigungen.</div><div><br></div><div>Sie erhalten diese Benachrichtigung, weil Ihre Organisation sich dafür entschieden hat, Benachrichtigungen vor Reiseantritt zu erhalten. Bei weiteren Fragen zu dieser Benachrichtigung wenden Sie sich bitte an das Reiserisikomanagementteam Ihrer Organisation oder senden Sie eine Informationsanfrage an <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a>.</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  },
  [LOCALES.IT]: {
    subject: 'Notifica prima del viaggio',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Notifica prima del viaggio'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>È previsto un viaggio verso le seguenti destinazioni. Di seguito sono riportati i consigli di viaggio più recenti per queste destinazioni insieme a eventuali avvisi attivi.</div><div><br></div><div>Stai ricevendo questa notifica perché la tua organizzazione ti ha scelto di ricevere notifiche prima del viaggio. Per ulteriori domande su questa notifica, contatta il team di gestione del rischio di viaggio della tua organizzazione o invia una richiesta di informazioni a <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a>.</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  },
  [LOCALES.JA]: {
    subject: '旅行前の通知',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': '旅行前の通知'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>以下の目的地へのご旅行が予定されています。これらの目的地に関する最新の旅行アドバイスとアクティブなアラートを以下でご覧ください。</div><div><br></div><div>この通知を受け取るのは、所属する組織が旅行前通知の受信を選択しているためです。この通知についてさらに質問がある場合は、組織の旅行リスク管理チームに連絡するか、 <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a> に情報提供のリクエストを送信してください。</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  },
  [LOCALES.PT]: {
    subject: 'Notificação antes da viagem',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Notificação antes da viagem'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Você está programado para viajar para o (s) destino (s) abaixo. Veja abaixo os conselhos de viagem mais recentes para esses destinos, juntamente com todos os alertas ativos.</div><div><br></div><div>Você está recebendo essa notificação porque sua organização optou por receber notificações pré-viagem. Para mais perguntas sobre essa notificação, entre em contato com a equipe de gerenciamento de riscos de viagem da sua organização ou envie uma solicitação de informações para <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a>.</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  },
  [LOCALES.ZH_HANS]: {
    subject: "旅行前通知",
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': "旅行前通知"
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>您计划前往以下目的地。请在下面找到这些目的地的最新旅行建议以及任何有效的警报。</div><div><br></div><div>您之所以收到此通知，是因为您的组织已选择您接收旅行前通知。有关此通知的更多问题，请联系贵组织的差旅风险管理团队或向 <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a> 提交信息请求。</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  },
  [LOCALES.ZH_HANT]: {
    subject: '旅遊前通知',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': '旅遊前通知'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>你預定前往以下目的地。請在下面找到這些目的地的最新旅遊建議以及任何有效警報。</div><div><br></div><div>您收到此通知是因為您的組織已選擇您接收出發前通知。如需有關此通知的進一步問題，請聯絡貴公司的旅遊風險管理團隊，或將資訊請求提交至 <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a>。</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  },
  [LOCALES.NL]: {
    subject: 'Melding voorafgaand aan de reis',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Melding voorafgaand aan de reis'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Het is de bedoeling dat u naar de onderstaande bestemming (en) reist. Hieronder vindt u het laatste reisadvies voor deze bestemmingen, samen met eventuele actieve meldingen.</div><div><br></div><div>Je ontvangt deze melding omdat je organisatie ervoor heeft gekozen om meldingen vóór de reis te ontvangen. Voor verdere vragen over deze melding kunt u contact opnemen met het team voor reisrisicobeheer van uw organisatie of een verzoek om informatie indienen bij <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a>.</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  },
  [LOCALES.HI]: {
    subject: 'यात्रा से पहले की सूचना',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'यात्रा से पहले की सूचना'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>आप नीचे दिए गए गंतव्यों की यात्रा करने के लिए निर्धारित हैं। कृपया किसी भी सक्रिय अलर्ट के साथ इन गंतव्यों के लिए नवीनतम यात्रा सलाह नीचे देखें।</div><div><br></div><div>आपको यह सूचना इसलिए मिल रही है क्योंकि आपके संगठन ने आपको यात्रा पूर्व सूचनाएं प्राप्त करने का विकल्प चुना है। इस सूचना के बारे में और प्रश्नों के लिए कृपया अपने संगठन की यात्रा जोखिम प्रबंधन टीम से संपर्क करें या <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a> पर जानकारी के लिए अनुरोध सबमिट करें।</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  },
  [LOCALES.RU]: {
    subject: 'Уведомление перед поездкой',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Уведомление перед поездкой'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Вы планируете отправиться в указанные ниже пункты назначения. Ниже вы найдете последние рекомендации по путешествиям по этим направлениям, а также все активные уведомления.</div><div><br></div><div>Вы получили это уведомление, потому что ваша организация разрешила вам получать предварительные уведомления о поездках. Если у вас возникнут дополнительные вопросы по этому уведомлению, обратитесь в отдел управления туристическими рисками вашей организации или отправьте запрос на получение информации по адресу <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a>.</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  },
  [LOCALES.PL]:{
    subject: "Powiadomienie przed podróżą",
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': "Powiadomienie przed podróżą"
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Planowana jest podróż do poniższych miejsc docelowych. Poniżej znajdują się najnowsze porady dotyczące podróży do tych miejsc docelowych wraz z wszelkimi aktywnymi alertami.</div><div><br></div><div>Otrzymujesz to powiadomienie, ponieważ Twoja organizacja zdecydowała się na otrzymywanie powiadomień przed podróżą. W przypadku dalszych pytań dotyczących tego powiadomienia skontaktuj się z zespołem zarządzania ryzykiem podróży w organizacji lub prześlij prośbę o informacje na adres <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a>.</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  },
  [LOCALES.HE]:{
    subject: "אתה אמור לנסוע ליעד (ים) שלהלן. אנא מצא להלן את עצות הנסיעות העדכניות ביותר עבור יעדים אלה יחד עם כל התראות פעילות.",
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': "אתה אמור לנסוע ליעד (ים) שלהלן. אנא מצא להלן את עצות הנסיעות העדכניות ביותר עבור יעדים אלה יחד עם כל התראות פעילות."
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;שם פרטי&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;שם משפחה&gt;</span></span>,</div><div><br></div><div>אתה אמור לנסוע ליעד (ים) שלהלן. אנא עיין בעצות הנסיעות העדכניות ביותר עבור יעדים אלה יחד עם כל התראות פעילות.</div><div><br></div><div>אתה מקבל הודעה זו מכיוון שהארגון שלך בחר לקבל התראות לפני הנסיעה. לשאלות נוספות בנוגע להודעה זו אנא פנה לצוות ניהול סיכוני הנסיעות של הארגון שלך או שלח בקשה לקבלת מידע אל<a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a>.</div>'
    }, {
      'order': 4,
      'type': 'TRIP_DETAILS'
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }]
  }
}